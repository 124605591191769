import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { localeStringConverter } from "../../i18n";
import { $lightGray3, $lightGray1 } from "../../theme";
import { localizedPriceStr, unixTimeToLocalizedStr } from "../../utils";
import { CartMode } from "../Cart";
import { StyledFlexHeaderRow } from "../FlexTable/FlexHeader";
import FlexBoxTable, { FlexBoxColumn } from "../FlexTable/FlexTable";

export interface FlexColumnProps {
  flex: string;
  align?: "left" | "right" | "center";
}

export const createRows = (rows: FlexColumnProps[]) => {
  let out = "";
  rows.forEach((item, index) => {
    out += `
    div:nth-child(${index + 1}) {
        flex: ${item.flex};
        ${rows.length - 1 === index ? "border-right-width: 1px" : ""}
        ${item.align ? "text-align:" + item.align + ";" : ""}
    }`;
  });
  return out;
};

export const flexTableBaseStyle = `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  > div {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 10px;
    border-color: ${$lightGray1};
    border-style: solid;
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 0;
    font-size: 12px;
  }

  > div:last-child {
    border-right-width: 1px;
  }
`;
export const createFlexTableRow = (rowSizes: FlexColumnProps[]) => styled.div`
  ${flexTableBaseStyle}
  ${createRows(rowSizes)}
`;

const StyledBottomRowTable = styled(FlexBoxTable)`
  ${StyledFlexHeaderRow} {
    border: 0;
  color: var(--text-primary, "#535B67") !important;
  border-bottom: 1px solid var(--border, #E4E4E7);
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  }
`;

const StyledTotalColumn = styled(FlexBoxColumn)`
  background-color: red;
`;

export const CartItemsBottomRow: React.FC<{
  total?: string;
  deliveryDate?: string | number;
  currency?: string;
  mode: CartMode;
}> = props => {
  const { t, i18n } = useTranslation();
  const hasDeliveryDate = props.deliveryDate && props.deliveryDate !== "0";
  return (
    <StyledBottomRowTable internalScroll data={[]}>
      <StyledTotalColumn
        name={
          <span>
            {t("TOTAL_IN") + " "}
            {(props.mode === CartMode.shop || props.mode === CartMode.orders) &&
              props.total &&
              localizedPriceStr(
                props.total,
                localeStringConverter(i18n.language),
                props.currency
              )}
          </span>
        }
        align="flex-end"
        flex="1 1 auto"
      />
    </StyledBottomRowTable>
  );
};
