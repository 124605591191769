import {
  Classes,
  Intent,
  PopoverProps,
  Label,
  Radio,
  RadioGroup,
  Spinner
} from "@blueprintjs/core";
import { DIALOG_BODY } from "@blueprintjs/core/lib/esm/common/classes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  SipShopCoreServicesVoCustomDecorEntry,
  SipShopCoreServicesVoLabeledData,
} from "../../services/productCatalogue";
import { addToCartWithMatId } from "../../store/actions/cart";
import { getCustomDecor } from "../../store/actions/shop";
import {
  CustomDecorState,
  MultiColor,
  setCustomDecorSelectionAction,
  setCustomMultiColorAction,
  setCustomDecorProductSelectionAction
} from "../../store/reducers/shop";
import { ReduxRootType } from "../../store/store";
import { ConnectedComponent, hexToRgba } from "../../utils";
import { DecorSuggest, productActionSuggestItemRenderer } from "./ProductItemSuggest";
import styled from "styled-components";
import FlexBoxTable, { FlexBoxColumn } from "../../components/FlexTable/FlexTable";
import { StyledFlexRow } from "../../components/FlexTable/FlexRow";
import { StyledFlexBody } from "../../components/FlexTable/FlexBody";
import { StyledLabel } from "../../components/Cart/OrderInfoBar";
import { Flex } from "../../components/Flex/Flex";
import { StyledButton } from "../../components/Cart/CornerBar";
import { chevronDown, configureIcon } from "../../assets/icons";
import { StyledDialog } from "../home/LoginForm";
import { StyledTopBarButton } from "../../components/Cart/CartTopbar";
const DIN_FORMAT_FEA = 'FEA';

const DecorInputWrapper = styled.div`
position: relative;
  svg{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`

const DecorInput = styled(DecorSuggest)`
padding: 8px 0px !important; 

border: 1px solid var(--border, #E4E4E7);
  >input {

    &:disabled {
      opacity: 0.5;
    }
    border: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: none !important ;
    background: white !important;
    color: var(--text-primary, #535B67) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
  }

  .bp5-input-action {
    padding-top: 50px;
  }

  >input:disabled + .bp5-input-action {
  opacity: 0.5;
}
`;

export const StyledRadioGroup = styled(RadioGroup)`

  .bp5-control input:checked ~ .bp5-control-indicator {
      background-color: white !important;
      outline: none !important;
  }
  .bp5-control .bp5-control-indicator {
    box-shadow: inset 0 0 0 1px var(--bg-primary, #00703D) !important;
  }

  .bp5-control.bp5-radio input:checked ~ .bp5-control-indicator::before {
    background-image: radial-gradient(var(--bg-primary, #00703D), var(--bg-primary, #00703D) 45%, transparent 32%) !important;
  }
  
`

const StyledFlexBoxColumn = styled.div`
  background-color: ${hexToRgba('#00703D', 0.1)} !important;  
`

const mapStateToProps = (state: ReduxRootType) => ({
  customDecor: state.shop.customDecor,
  matId: state.shop.selection && state.shop.selection.group.matId,
  generatedProductId:
    state.shop.customDecor && state.shop.customDecor.productId ? state.shop.customDecor.productId : '',
  disabled: state.shop.selection
    ? !state.shop.selection.group.allowsCustomDecors
    : true,
  isComplete: !!(state.shop.customDecor && state.shop.customDecor.isComplete),
  sale: state.shop.selection && state.shop.selection.item ? state.shop.selection.item.sale : 0,
  saleFinalDate: state.shop.selection && state.shop.selection.item ? state.shop.selection.item.saleFinalDate : '',
  descr: state.shop.selection && state.shop.selection.group && state.shop.selection.group.description ? state.shop.selection.group.description : ''
});

const CustomDecorTable = styled(FlexBoxTable)`
  flex-grow: 1;
  flex-basis: 30%;
  background-color: var(--bg-secondary, #F4F4F5);
  border-bottom: 1px solid var(--border, #E4E4E7);;
  ${StyledFlexRow} {
    cursor: pointer;
    &:has(.selected){
      background-color: ${hexToRgba('#00703D', 0.1)} !important;     
    }
    &:hover {
      background-color: ${hexToRgba('#00703D', 0.1)} !important;      
    }
  }

  ${StyledFlexBody} {
    max-height: 280px;
    overflow: auto;
  }
`;


const getLabelledDataFromDecorList = (
  customDecor: CustomDecorState | null
): SipShopCoreServicesVoLabeledData[] => {
  if (!customDecor) {
    return [];
  }
  if (customDecor.allowsBiColor) {
    let mc = (!customDecor.multiColor || typeof customDecor.multiColor === 'undefined') ? MultiColor.uni : customDecor.multiColor;
    if ((mc === MultiColor.uni && !customDecor.uniColoredDecors) || (mc === MultiColor.biColor && !customDecor.biColoredDecors)) {
      return []
    }
    return mc === MultiColor.biColor ? customDecor.biColoredDecors.map(item => item.baseDecor) : customDecor.uniColoredDecors.map(item => item.baseDecor);
  } else {
    if (!customDecor.uniColoredDecors) {
      return []
    }
    return customDecor.uniColoredDecors.map(item => item.baseDecor);
  }
};

const getEntriesFromDecors = (
  customDecor: CustomDecorState | null,
  t: Function
): SipShopCoreServicesVoCustomDecorEntry[] => {
  if (!customDecor || customDecor.selectedDecor === null || typeof customDecor.selectedDecor === 'undefined') {
    return [];
  }
  let mc = (!customDecor.multiColor || typeof customDecor.multiColor === 'undefined') ? MultiColor.uni : customDecor.multiColor;
  if ((mc === MultiColor.uni && !customDecor.uniColoredDecors) || (mc === MultiColor.biColor && !customDecor.biColoredDecors)) {
    return []
  }
  let ret: SipShopCoreServicesVoCustomDecorEntry[] = [];
  if (mc === MultiColor.biColor) {
    for (let i = 0; i < customDecor.biColoredDecors[customDecor.selectedDecor].decors.length; i++) {
      ret.push(customDecor.biColoredDecors[customDecor.selectedDecor].decors[i])
    }
  } else {
    for (let i = 0; i < customDecor.uniColoredDecors[customDecor.selectedDecor].decors.length; i++) {
      if (customDecor.uniColoredDecors[customDecor.selectedDecor].dinFormat === DIN_FORMAT_FEA) {
        let bcData = customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].baseColor.data;
        if (customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].outsideDecor.data === '' && (bcData === '4' || bcData === '5' || bcData === '7')) {
          customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].outsideDecor.label = t("DECOR_ALU_COVER");
        }
      }
      ret.push(customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i])
    }
  }
  return ret;
};

const getAluAnnotation = (
  customDecor: CustomDecorState | null,
  selectedDecor: SipShopCoreServicesVoCustomDecorEntry[],
  remark: string
): string => {
  if (!customDecor || customDecor.selectedDecor === null || typeof customDecor.selectedDecor === 'undefined' || selectedDecor.length === 0) {
    return '';
  }
  let mc = (!customDecor.multiColor || typeof customDecor.multiColor === 'undefined') ? MultiColor.uni : customDecor.multiColor;
  if (mc === MultiColor.uni && customDecor.uniColoredDecors) {
    for (let i = 0; i < customDecor.uniColoredDecors[customDecor.selectedDecor].decors.length; i++) {
      if (customDecor.uniColoredDecors[customDecor.selectedDecor].dinFormat !== DIN_FORMAT_FEA) {
        continue;
      }
      let bcData = customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].baseColor.data;
      if (customDecor.uniColoredDecors[customDecor.selectedDecor].decors[i].outsideDecor.data === '' && (bcData === '4' || bcData === '5' || bcData === '7')) {
        return remark;
      }
    }
  }
  return '';
}

export const CustomDecorSelect: React.FC<{
  label: string;
  selectedItem?: SipShopCoreServicesVoLabeledData | null;
  disabled?: boolean;
  inline?: boolean;
  items?: SipShopCoreServicesVoLabeledData[];
  onSelect: (item: SipShopCoreServicesVoLabeledData) => void;
  popoverProps?: (Partial<PopoverProps> & object) | undefined;
  displayLabel?: boolean;
}> = ({
  label,
  selectedItem,
  disabled,
  items,
  onSelect,
  popoverProps,
  inline,
  displayLabel = true,
}) => {
    const { t } = useTranslation();
    const iconElement = items && items.length > 1 ?  <span>{chevronDown}</span> : undefined
    return (
      <div>
        {displayLabel && <StyledLabel className={inline ? Classes.INLINE : undefined}>
          <span>{t(label)}</span>
        </StyledLabel>}
        <DecorInputWrapper>
        <DecorInput
          inputProps={{ placeholder: t(label), "aria-label": t(label), rightElement: iconElement }}
          selectedItem={selectedItem}
          disabled={disabled}
          items={items ? items : []}
          itemRenderer={productActionSuggestItemRenderer}
          onItemSelect={onSelect}
          inputValueRenderer={item => item.label}
          itemPredicate={(query, item) => item.label.indexOf(query) !== -1}
          popoverProps={
            !popoverProps ? { minimal: true, placement: "bottom", matchTargetWidth: true } : { ...popoverProps, minimal: true, placement: "bottom", matchTargetWidth: true }
          }
        />
        </DecorInputWrapper>
      </div>
    );
  };

interface CustomDecorConfiguratorProps
  extends ConnectedComponent<typeof mapStateToProps> { }

const CustomDecorConfigurator: React.FC<
  CustomDecorConfiguratorProps
> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const allowsBiColor = !!(props.customDecor && props.customDecor.allowsBiColor);
  const isBiColored = allowsBiColor && props.customDecor!.multiColor! === MultiColor.biColor;
  const decorSelected = !!(props.customDecor &&
    props.customDecor.selectedDecor !== null &&
    props.customDecor.selectedDecor !== undefined
  );

  const [decorData, setCustomDecors] = useState(
    getLabelledDataFromDecorList(props.customDecor)
  );

  const [selectedDecor, setSelectedDecor] = useState(
    getEntriesFromDecors(props.customDecor, t)
  );
  const handleSelectedProduct = (customDecor: CustomDecorState | null): string | null => {
    if (customDecor && customDecor.selectedCustomDecor !== undefined && customDecor.selectedCustomDecor !== null && selectedDecor.length > 0) {
      return selectedDecor[customDecor.selectedCustomDecor].matId;
    } else {
      return null;
    }
  };
  const [selectedProduct, setSelectedProduct] = useState(
    handleSelectedProduct(props.customDecor)
  );

  const [aluAnnotation, setAluAnnotation] = useState(getAluAnnotation(props.customDecor, selectedDecor, t("REMARK_ALU_COVER")));

  useEffect(() => {
    let newLds = getLabelledDataFromDecorList(props.customDecor);
    let update = false;
    if (newLds.length === decorData.length) {
      for (let i = 0; i < newLds.length; i++) {
        if (newLds[i].data !== decorData[i].data) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) {
      setCustomDecors(newLds);
    }
    update = false;
    let newSelDecor = getEntriesFromDecors(props.customDecor, t);
    if (newSelDecor.length === selectedDecor.length) {
      for (let i = 0; i < newSelDecor.length; i++) {
        if (newSelDecor[i].matId !== selectedDecor[i].matId) {
          update = true;
          break;
        }
      }
    } else {
      update = true;
    }
    if (update) {
      setSelectedDecor(newSelDecor);
      if (newSelDecor.length > 0) {
        setSelectedProduct(newSelDecor[0].matId);
      } else {
        setSelectedProduct(null);
      }
      setAluAnnotation(getAluAnnotation(props.customDecor, newSelDecor, t("REMARK_ALU_COVER")));
      return;
    }
    let newSelectedProduct = handleSelectedProduct(props.customDecor);
    if ((newSelectedProduct === null && selectedProduct !== null)
      || (newSelectedProduct !== null && selectedProduct === null)
      || (newSelectedProduct !== null && selectedProduct !== null && newSelectedProduct !== selectedProduct)) {
      setSelectedProduct(newSelectedProduct);
    }
  }, [props.customDecor]);


  function renderCellLabel(cellData: any, isSelected: boolean) {
    const label = cellData.label ? cellData.label : "-"
    if (cellData) {
      return isSelected ? (<div className="selected">{label}</div>) : label;
    }
    return '';
  }
  return (
    <div className="product-actions">
      <Flex flexDirection="column" gap="12px">
        {!props.disabled && props.matId && (
            <>
              <span className="custom-decor-title">{t("CUSTOM_DECOR")}</span>
              <StyledButton
                disabled={props.disabled || !props.matId}
                onClick={() => {
                  if (props.matId) {
                    props.dispatch(getCustomDecor(props.matId));
                    setIsOpen(true);
                  }
                }}
                icon={configureIcon}
                style={{width:"fit-content"}}
              >
                {t("CUSTOM_DECOR_CONFIGURE")}
              </StyledButton>
            </>
          )}
        <StyledDialog
          onClose={() => setIsOpen(false)}
          title={t("CUSTOM_DECOR_CONFIGURE")}
          isCloseButtonShown={true}
          canOutsideClickClose
          canEscapeKeyClose
          isOpen={isOpen}
          style={{ width: '800px' }}
        >
          <div className={DIALOG_BODY}>
            {props.customDecor === null ? (
              <Spinner />
            ) : (
              <Flex flexDirection="column" gap="12px">
                <StyledRadioGroup
                  disabled={!allowsBiColor}
                  inline
                  onChange={e =>
                    props.dispatch(setCustomMultiColorAction(e.currentTarget.value as MultiColor))
                  }
                  selectedValue={
                    props.customDecor && props.customDecor.multiColor ? props.customDecor.multiColor : MultiColor.uni
                  }
                >
                  <Radio
                    label={"Mono-Colour"}
                    value={MultiColor.uni}
                  />
                 {allowsBiColor && <Radio
                    label={"Bi-Colour"}
                    value={MultiColor.biColor}
                  />}
                </StyledRadioGroup>

                <CustomDecorSelect
                  label={isBiColored ? "DECOR_OUTSIDE" : "DECOR"}
                  displayLabel={false}
                  selectedItem={
                    props.customDecor && props.customDecor.selectedDecor !== undefined && props.customDecor.selectedDecor !== null
                      ? decorData[props.customDecor.selectedDecor]
                      : null
                  }
                  disabled={props.disabled}
                  items={decorData}
                  onSelect={item => {
                    if (!decorData) {
                      return;
                    }
                    const selectedDecorIndex = decorData.findIndex(
                      decor => decor === item
                    );
                    if (selectedDecorIndex !== -1) {
                      props.dispatch(setCustomDecorSelectionAction(selectedDecorIndex));
                      props.dispatch(setCustomDecorProductSelectionAction(0));
                    }
                  }}
                />
                 {selectedDecor && selectedDecor.length > 0 && <CustomDecorTable
                    draggable={false}
                    selectionPredicate={(cde: SipShopCoreServicesVoCustomDecorEntry) =>
                      cde.matId === selectedProduct
                    }
                    onRowClick={({ rowData }) => {
                      if (!selectedDecor) {
                        return;
                      }
                      const selectedCustomDecorIndex = selectedDecor.findIndex(decor => decor === rowData);
                      selectedCustomDecorIndex !== -1 &&
                        props.dispatch(setCustomDecorProductSelectionAction(selectedCustomDecorIndex));
                    }}
                    data={selectedDecor}
                  >
                    <FlexBoxColumn
                        dataKey="matId"
                        flex="0.4 0 80px"
                        name={t("ARTICLENUMBER")}
                      />
                        
                     
                      <FlexBoxColumn
                        dataKey="baseColor"
                        flex="0.4 0 80px"
                        name={t("BASE_COLOR")}
                      >
                        {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                      </FlexBoxColumn>
                    <FlexBoxColumn
                      dataKey="insideDecor"
                      flex="0.4 0 80px"
                      name={t("DECOR_INSIDE")}
                    >
                      {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                    </FlexBoxColumn>
                    <FlexBoxColumn
                      dataKey="outsideDecor"
                      flex="0.4 0 80px"
                      name={t("DECOR_OUTSIDE")}
                    >
                      {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                    </FlexBoxColumn>
                    <FlexBoxColumn name={t("DECOR_SEAL")} dataKey="seal" flex="0.4 0 80px">
                      {cellProps => renderCellLabel(cellProps.data, cellProps.rowData.matId === selectedProduct)}
                    </FlexBoxColumn>

                  </CustomDecorTable>}
                <Label>{aluAnnotation}</Label>
                <Flex gap="20px">
                  <StyledButton onClick={() => setIsOpen(false)}>
                    {t("CANCEL")}
                  </StyledButton>
                  <StyledTopBarButton
                    disabled={!props.isComplete}
                    onClick={() => {
                      if (props.generatedProductId && props.generatedProductId!.length > 0) {
                        props.dispatch(
                          addToCartWithMatId(props.generatedProductId as string, false)
                        );
                        setIsOpen(false);
                      }
                    }}
                    intent={Intent.SUCCESS}
                  >
                    {t("CART")}
                  </StyledTopBarButton>
                  </Flex>
              </Flex>
            )}
          </div>
        </StyledDialog>
      </Flex>
    </div>
  );
};

export default connect(mapStateToProps)(CustomDecorConfigurator);
