import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { debounce } from "../utils";
import SplitPane, { SplitPaneProps } from "react-split-pane";

// minimal space from top of screen
const minTopSpace = 120;
// size of the collapsed order panel
const minimizedSize = 85;

type SplitPaneWrapperProps = { children?: React.ReactNode } & SplitPaneProps;

const SplitPaneWrapper = (props: SplitPaneWrapperProps) => {
  
  return (
    <StyledSplitPane>
      {/* @ts-ignore SplitPane handles children internally, even though it's not typed for it.*/}
      <SplitPane {...props} />
    </StyledSplitPane>
  );
};

const StyledSplitPane = styled.div`
  .Pane1 {
    /* z-index: 0; */
  }
  .Resizer {
    /* background: red; */
    opacity: 0;
    z-index: 1 !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    z-index: 1 !important;
    height: 60px;
    margin: -90px 0;
    border-top: 5px solid $light-gray4;
    border-bottom: 5px solid $light-gray4;
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }
`;

const SplitPage: React.FC<{
  layout?: "horizontal" | "vertical";
  // size of the second panel
  size?: number;
  minimized?: boolean;
  onSetSize: (size: number | undefined) => void;
  children?: React.ReactNode;
}> = ({ size, layout, children, onSetSize, minimized }) => {
  const [panelSize, setPanelSize] = useState(size);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    if (minimized) {
      setPanelSize(minimizedSize);
    }
  }, [minimized]);

  const onResize = useCallback(
    debounce((e) => {
      if (typeof panelSize === "number") {
        onSetSize(Math.min(window.innerHeight - minTopSpace, panelSize));
      }
    }, 200),
    [panelSize, onSetSize]
  );

  useEffect(() => {
    setPanelSize(size);
  }, [size]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  const onDragFinish = useCallback(
    (newSize: number) => {
      onSetSize(newSize);
    },
    [setDragging]
  );

  const onSetDragging = useCallback(() => setDragging(true), [setDragging]);
  return (
    <SplitPaneWrapper
      size={panelSize}
      minSize={minimizedSize}
      maxSize={window.innerHeight - minTopSpace}
      split={layout ? layout : "horizontal"}
      primary={"second"}
      onDragStarted={onSetDragging}
      onDragFinished={onDragFinish}
    >
      {children}
    </SplitPaneWrapper>
  );
};

export default connect()(SplitPage);
