import { Button, Classes } from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { getSelectionURL } from "../Header";
import { cartOpenAction } from "../store/reducers/bottomCart";
import { ReduxRootType } from "../store/store";
import { ConnectedComponent } from "../utils";
import { useNavigate } from "react-router-dom";
import { cartIcon } from "../assets/icons";

const StyledNumberOnlyField = styled.span``;
const StyledNumberLabelField = styled.span``;

const StyledCartButton = styled(Button)`
  .bp5-button-text {
    ${StyledNumberLabelField} {
      display: none;
    }
    ${StyledNumberOnlyField} {
      display: inline;
    }
    @media screen and (min-width: 1024px) {
      ${StyledNumberOnlyField} {
        display: none;
      }
      ${StyledNumberLabelField} {
        display: inline;
      }
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const mapStateToProps = (state: ReduxRootType) => ({
  count: state.cart.current && state.cart.current.items.length,
  selectionURL: getSelectionURL(state.shop.selection)
});

interface CartButtonProps extends ConnectedComponent<typeof mapStateToProps> { }

const CartButton: React.FC<CartButtonProps> = ({
  count,
  dispatch,
  selectionURL
}) => {
  const { t } = useTranslation();
  const c = count ? count : 0;
  const navigate = useNavigate();
  return (
    <StyledCartButton
      className={Classes.MINIMAL}
      icon={cartIcon}
      loading={count === null}
      onClick={() => {
        navigate(selectionURL);
        dispatch(cartOpenAction());
      }}
    >
      <StyledNumberOnlyField>{c}</StyledNumberOnlyField>
      <StyledNumberLabelField>
        {t("ITEM_IN_CART", { count: c })}
      </StyledNumberLabelField>
    </StyledCartButton>
  );
};

export default connect(mapStateToProps)(CartButton);
