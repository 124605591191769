import { Classes, InputGroupProps, Label, MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import React from "react";
import { useTranslation } from "react-i18next";

import { SipShopCoreServicesVoLabeledData } from "../../services/productCatalogue";

export const DecorSuggest = Suggest.ofType<SipShopCoreServicesVoLabeledData>();

export const productActionSuggestItemRenderer = (
  item: SipShopCoreServicesVoLabeledData,
  { handleClick, modifiers, query }
) => <MenuItem onClick={handleClick} key={item.label} text={item.label} />;

export const ProductActionSuggest: React.FC<{
  inline?: boolean;
  disabled?: boolean;
  labelKey: string;
  item?: SipShopCoreServicesVoLabeledData;
  items: SipShopCoreServicesVoLabeledData[];
  inputProps?:
  | (InputGroupProps & React.InputHTMLAttributes<HTMLInputElement>)
  | undefined;
  onItemSelect: (item: SipShopCoreServicesVoLabeledData) => void;
}> = props => {
  const { t } = useTranslation();

  // className="bp3-inline"
  return (
    <Label className={props.inline ? Classes.INLINE : undefined}>
      <span>{t(props.labelKey)}</span>
      <DecorSuggest
        inputProps={
          props.inputProps
            ? { placeholder: t(props.labelKey), ...props.inputProps }
            : { placeholder: t(props.labelKey) }
        }
        selectedItem={props.item}
        disabled={props.disabled}
        // openOnKeyDown
        // initialContent={t(props.labelKey)}
        items={props.items}
        closeOnSelect={true}
        itemRenderer={productActionSuggestItemRenderer}
        onItemSelect={props.onItemSelect}
        inputValueRenderer={item => item.label}
        popoverProps={{ minimal: true }}
      />
    </Label>
  );
};
