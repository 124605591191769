import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { localeStringConverter } from "../../i18n";
import {
  asyncActionWrapper,
  getCartService,
  getCatalogueService
} from "../../services/api";
import {
  CurrentCart,
  SipShopCoreServicesVoCartEnv,
  SipShopCoreServicesVoItem,
  SipShopCoreServicesVoOrder,
  SipShopCoreServicesVoProduct,
  SipShopCoreServicesVoProductGroup
} from "../../services/productCatalogue";
import {
  getCartItemFromProduct,
  parseFloatFromLocalizedStr
} from "../../utils";
import { cartOpenAction } from "../reducers/bottomCart";
import {
  editCartItemQty,
  setCartAction,
  setCartEnvAction,
  setCartKeyAction,
  setCartOrder,
  setLoadingAvailabilityAction,
  setOrderInProgressAction,
  setQuickInsertError,
  setQuickInsertProducts,
  undercutCartItemQty
} from "../reducers/cart";
import { ErrorType } from "../reducers/error";
import { actionAddMessages } from "../reducers/messages";
import { addErrorToast, addToast } from "../reducers/toasts";
import { ReduxRootType } from "../store";
import { exportOrder } from "./order";

/**
 * Ids used to map draggable ids to the product tables
 */
export enum ProductTableDraggableId {
  NO_DECOR = "NO_DECOR",
  ONESIDED_DECOR = "ONESIDED_DECOR",
  BOTHSIDED_DECOR = "BOTHSIDED_DECOR",
  PRODUCT = "PRODUCT",
  OTHER = "OTHER"
}

/**
 * update the state of the current cart locally and on remote
 * @param key
 * @param newValue
 */
export const placeOrder = (orderName: string, orderRemark: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();

  const cart = state.cart.current;
  if (!cart) {
    return;
  }
  // update name of order and remark
  cart.name = orderName;
  cart.remark = orderRemark;
  dispatch(setOrderInProgressAction(true));
  const call = getCartService().operations.order(cart);

  try {
    const order = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.placeOrder,
      false,
      false
    )) as SipShopCoreServicesVoOrder;

    // check if we got an order
    if (!order || order.status !== "complete") {
      dispatch(setOrderInProgressAction(false));
      dispatch(addErrorToast("ORDER_ERROR", true));
      return;
    }
    dispatch(setOrderInProgressAction(false));
    // dispatch(setCartAction(newCart));
    dispatch(resetCart());
    dispatch(
      actionAddMessages([
        {
          msg: "ORDER_SENT_COMPLETE",
          localize: true,
          icon: "tick-circle",
          intent: "success"
        }
      ])
    );
  } catch (e) {
    dispatch(setOrderInProgressAction(false));
    dispatch(addErrorToast("ORDER_ERROR", true));
    console.error(e);
  }
};

/**
 * update the state of the current cart locally and on remote
 * @param key
 * @param newValue
 */
export const updateCartState = (
  key: keyof CurrentCart,
  newValue: any,
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();

  // just to be sure apply current changes to the cart state
  const cart: CurrentCart = { ...state.cart.current } as CurrentCart;
  (cart as any)[key] = newValue;

  dispatch(modifyCart(cart));

  // notify user
  dispatch(
    addToast({
      message: "CART_UPDATED",
      icon: "tick-circle",
      intent: "success",
      localize: true
    })
  );
};

/**
 *
 */
export const resetCart = () => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const cartBackup = getState().cart.current;
  dispatch(setCartAction(null));
  const call = getCartService().operations.resetCart(null);

  try {
    const newCart = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.addToCart
    )) as CurrentCart;
    dispatch(setCartAction(newCart));
  } catch (e) {
    console.error(e);
    // restore old cart
    if (cartBackup) {
      dispatch(setCartAction(cartBackup));
    }
  }
};

/**
 * put all items from order into cart
 * @param order
 * @param mergeWithCurrent whether to merge the current cart items with the
 *     order items
 */
export const resetCartToOrder = (
  order: SipShopCoreServicesVoOrder,
  mergeWithCurrent: boolean = false,
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();
  let cart = state.cart.current;
  if (!cart) {
    return;
  }

  // remove cart item
  const items = appendCartItem(
    mergeWithCurrent
      ? appendCartItem([...cart.items, ...order.items])
      : appendCartItem(order.items)
  );
  cart.items = items;
  dispatch(modifyCart(cart));
};

/**
 *
 * @param silent prevent error dialog to be triggered
 */
export const reloadAvailability = (silent?: boolean) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const call = getCartService().operations.getAvailability();
  dispatch(setLoadingAvailabilityAction(true));
  try {
    const newCart = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.addToCart,
      silent
    )) as CurrentCart;
    dispatch(setCartAction(newCart));
  } catch (e) {
    console.error(e);
  }
  dispatch(setLoadingAvailabilityAction(false));
};

/**
 *
 * @param productId
 * @param posNo
 */
export const removeFromCart = (
  productId: string,
  posNo: number | string
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();
  let cart = state.cart.current;
  if (!cart) {
    return;
  }

  // remove cart item
  const items = appendCartItem(
    cart.items.filter(
      item => item.id !== productId && item.posNoFormatted !== posNo
    )
  );
  cart.items = items;
  dispatch(modifyCart(cart));
};

/**
 *
 * @param droppableId
 * @param sourceIndex
 * @param destinationIndex
 * @param itemId
 */
export const dragToCart = (
  sourceDroppableId: ProductTableDraggableId,
  sourceIndex: number,
  destinationIndex: number,
  itemId: string
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();
  const selection = state.shop.selection;

  if (!selection) {
    console.warn("cannot drop item after selection was changed");
    return;
  }

  let product: SipShopCoreServicesVoProduct | null = null;

  const getProductForGroup = (
    key: keyof SipShopCoreServicesVoProductGroup
  ): SipShopCoreServicesVoProduct | null =>
    selection.group[key] && selection.group[key].length >= sourceIndex + 1
      ? selection.group[key][sourceIndex]
      : null;
  switch (sourceDroppableId) {
    case ProductTableDraggableId.BOTHSIDED_DECOR:
      product = getProductForGroup("bothSidesDecorProducts");
      break;
    case ProductTableDraggableId.ONESIDED_DECOR:
      product = getProductForGroup("singleSideDecorProducts");
      break;
    case ProductTableDraggableId.NO_DECOR:
      product = getProductForGroup("onlyBaseColorProducts");
      break;
    case ProductTableDraggableId.OTHER:
      product = getProductForGroup("noDecorProducts");
      break;
    default:
      product = null;
  }

  // validate product
  if (product === null || product.id.toString() !== itemId) {
    console.warn("Something went wrong item does not math dragged item", {
      product,
      itemId
    });
    return;
  }

  dispatch(addToCart(product, destinationIndex));
};

/**
 * Add item to cart at optional position
 */
export const addToCart = (
  product: SipShopCoreServicesVoProduct,
  index?: number
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();
  let cart = state.cart.current;
  if (!cart) {
    return;
  }

  const newItem = getCartItemFromProduct(product);
  cart = { ...cart, items: appendCartItem(cart.items, newItem, index) };
  try {
    await dispatch(modifyCart(cart));
    // dispatch(addToast({
    //   message: 'Added to cart ' + product.name,
    //   icon: 'shopping-cart',
    //   intent: 'success'
    // }));
    const itemIndex = cart.items.findIndex(
      item =>
        item.id === product.id && item.posNoFormatted === newItem.posNoFormatted
    );
    // open cart
    dispatch(cartOpenAction());

    // select item for qty edit
    dispatch(editCartItemQty(itemIndex));
  } catch (e) {
    console.error(e);
  }
};

export const addToCartWithMatId = (
  matId: string,
  quickInsert: boolean = true,
  autoAdd: boolean = true
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const correctedMatId = matId.toUpperCase().trim();
  dispatch(setQuickInsertError(undefined));
  const searchOp = getCatalogueService().operations.searchProductsByMatId(
    correctedMatId
  );
  try {
    const products = (await asyncActionWrapper(
      searchOp as any,
      dispatch,
      ErrorType.loadCart,
      true
    )) as SipShopCoreServicesVoProduct[];
    if (autoAdd && products.length > 0) {
        if (products.length === 1) {
            dispatch(addToCart(products[0]));
        } else {
            for (let i = 0; i < products.length; i++) {
                if (products[i].matId === correctedMatId) {
                    dispatch(addToCart(products[i]));
                    break;
                }
            }
        }
      return;
    }
    if (quickInsert) {
      dispatch(setQuickInsertProducts(products));
    }
    dispatch(setQuickInsertError(undefined));
    // dispatch(setCartAction(cart as CurrentCart));
  } catch (e: any) {
    console.error(e);
    if (e.msg) {
      dispatch(setQuickInsertProducts(null));
      dispatch(setQuickInsertError(e.msg));
    }
  }
};

/**
 *
 * @param posNo
 * @param itemId
 * @param newQty
 */
export const changeQtyInCart = (
  posNo: number | string,
  itemId: string,
  newQty: string,
  ignoreUderCut: boolean = false
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  // clean undercut dialog
  dispatch(undercutCartItemQty(undefined));

  const state = getState();
  const locale = state.user
    ? localeStringConverter(state.user!.locale)
    : undefined;
  if (!state.cart.current) {
    return;
  }
  const item = state.cart.current.items.find(
    item => item.id === itemId && item.posNoFormatted === posNo
  );
  if (!item) {
    console.error("no item to change qty found");
    return;
  }
  const newQtyValue = parseFloatFromLocalizedStr(newQty, locale);
  const minQtyValue = parseFloat(item.minOrderCntPerUnit);

  // update qtn on item
  if (parseFloatFromLocalizedStr(item.cnt) === newQtyValue) {
    dispatch(editCartItemQty(undefined));
    return;
  }

  if (!ignoreUderCut && newQtyValue < minQtyValue) {
    dispatch(undercutCartItemQty(newQtyValue));
    return;
  }

  // stop editing mode
  dispatch(editCartItemQty(undefined));

  item.cnt = "" + newQtyValue;
  const newCart = { ...state.cart.current };

  // update UI
  dispatch(setCartAction(newCart));

  dispatch(modifyCart(newCart));
};

/**
 * reload cart state
 */
export const reloadCart = () => async (
  dispatch: ThunkDispatch<{}, {}, any>
) => {
  // put cart into loading state
  dispatch(setCartAction(null));
  const getCart = getCartService().operations.getCurrentCart();

  try {
    const cart = await asyncActionWrapper(
      getCart as any,
      dispatch,
      ErrorType.loadCart
    );
    dispatch(setCartAction(cart as CurrentCart));
  } catch (e) {
    dispatch(setCartAction(null));
  }
};

/**
 * reload cart env values
 * @param source
 * @param destination
 */
export const reloadCartEnv = () => async (dispatch: Dispatch) => {
  const getCart = getCartService().operations.getCartEnvironment();

  try {
    const env = await asyncActionWrapper(
      getCart as any,
      dispatch,
      ErrorType.loadCart
    );
    //
    dispatch(setCartEnvAction(env as SipShopCoreServicesVoCartEnv));
  } catch (e) {
    // dispatch(setCartAction(null));
  }
};

/**
 * update cart item order
 * @param source
 * @param destination
 */
export const reorderCart = (source: number, destination: number) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  dispatch(setCartOrder(source, destination));

  const state = getState();
  // update remote state
  dispatch(
    updateCartState("items", state.cart.current ? state.cart.current.items : [])
  );
};

/**
 * update cart state on server and set response as active state
 * @param cart
 */
const modifyCart = (cart: CurrentCart | null) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const call = getCartService().operations.modifyCart(cart);
  try {
    const newCart = await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.addToCart,
      false,
      false
    );
    dispatch(setCartAction(newCart as CurrentCart));

    // if cart was modified make sure we are on the shop page so that
    // modifications can be seen
    // if( location && navigate){
    //   if (location.pathname.indexOf("/order") === 0) {
    //     navigate("/");
    //   }
    // }
    
  } catch (e) {
    dispatch(addErrorToast("ORDER_ERROR", true));
  }
};

/**
 *
 */
export const exportCart = () => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const cart = getState().cart.current;
  if (!cart) {
    return;
  }
  return dispatch(exportOrder(cart.id));
};

export const uploadCart = (fileRef: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const call = getCartService().operations.uploadCartData({
    id: fileRef,
    name: "",
    fileName: "",
    fileType: "csv",
    type: ""
  });
  try {
    const newCart = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.uploadCSVError
    )) as SipShopCoreServicesVoOrder;

    dispatch(setCartAction(newCart));
  } catch (e) {
    console.error("Failed to upload cart", e);
  }
};
/**
 * Utils
 */

export const appendCartItem = (
  cart: SipShopCoreServicesVoItem[] | undefined,
  newItem?: SipShopCoreServicesVoItem,
  index?: number
) => {
  let itemIndex = 10;
  let items: SipShopCoreServicesVoItem[] = [];
  let itemAdded = false;

  const addItem = (item: SipShopCoreServicesVoItem) => {
    item.posNoFormatted = itemIndex;
    itemIndex += 10;
    items.push(item);
  };

  if (cart) {
    cart.forEach((item, i) => {
      if (newItem && index && i === index) {
        addItem(newItem);
        itemAdded = true;
      }
      addItem(item);
    });
  }

  if (newItem && !itemAdded) {
    addItem(newItem);
  }

  return items;
};

let updateTimeout: NodeJS.Timeout | undefined;
// debounce time in ms
const updateDebounceTime = 1000;

type ThunkAction<T> = (dispatch: Dispatch, getState: () => ReduxRootType) => T;

export const debounceWrapper = <T extends {}>(
  dispatch: Dispatch,
  getState: () => ReduxRootType
) => (call: ThunkAction<T>) => {
  clearTimeout(updateTimeout);
  return new Promise((resolve, reject) => {
    updateTimeout = setTimeout(() => {
      updateTimeout = undefined;
      resolve(call(dispatch, getState));
    }, updateDebounceTime);
  });
};

export const debouncedUpdateCartState = (
  key: keyof CurrentCart,
  newValue: any,
) => async (dispatch: Dispatch, getState: () => ReduxRootType) => {
  // update UI state
  dispatch(setCartKeyAction(key, newValue));

  // debounce network update
  return debounceWrapper(dispatch, getState)(updateCartState(key, newValue));
};
