import { Button, Classes, Dialog, InputGroup, Label, Spinner } from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER,
  DIALOG_FOOTER_ACTIONS
} from "@blueprintjs/core/lib/esm/common/classes";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { CurrentCart } from "../../services/productCatalogue";
import {
  placeOrder,
  reloadAvailability,
  resetCartToOrder
} from "../../store/actions/cart";
import { setCartMinimizedAction } from "../../store/reducers/bottomCart";
import { setOrderEdit } from "../../store/reducers/order";
import { ReduxRootType } from "../../store/store";
import { $lightGray4 } from "../../theme";
import { ConnectedComponent, hexToRgba } from "../../utils";
import { CartMode } from "../Cart";
import { StyledOverlay } from "../ResetPassword";
import CartOrderEditEmailDialog from "./CartOrderEditEmailDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex } from "../Flex/Flex";
import { cartIcon, chevronDown, chevronUp, DeleteOrderIcon, EditIcon } from "../../assets/icons";
import { StyledDialog } from "../../pages/home/LoginForm";
import { StyledButton } from "./CornerBar";

const mapStateToProps = (state: ReduxRootType) => ({
  selectedOrder: state.orders.detail && state.orders.detail,
  orderInProgress: state.cart.orderInProcess,
  isCartEmpty: state.cart.current ? state.cart.current.items.length === 0 : true,
  numberOfItems: state.cart.current && state.cart.current.items.length
});

interface CartTopbarProps extends ConnectedComponent<typeof mapStateToProps> {
  onToggleMinimized?: (mini: boolean) => void;
  mode: CartMode;
  isMinimized?: boolean;
  order?: CurrentCart;
}

const StyledTopBarOpenButton = styled(Button)`
  position: relative;
  z-index: 2 !important;
  background: var(--bg-secondary, #F4F4F5) border-box !important;
  border: 0;
  color: var(--text-primary, "#535B67") !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 250px;
  height: 30px;
  --r: 10px;
  border: solid #0000;
  border-width: 0 var(--r);
  border-radius: calc(2*var(--r)) calc(2*var(--r)) 0 0/var(--r);
  mask: 
    radial-gradient(var(--r) at var(--r) 0,#0000 98%,#000 101%)
      calc(-1*var(--r)) 100%/100% var(--r) repeat-x,
    conic-gradient(#000 0 0) padding-box;
    box-shadow: none !important;
    left: -10px;
    
  
`;

const BoxShadow = styled.div`
      box-shadow: 
    -4px -3.5px 4px rgba(0, 0, 0, 0.10),  
    4px -3.5px 4px rgba(0, 0, 0, 0.10) !important;
    position: relative;
    width: 230px;
    border-radius: 15px 15px 0 0;

`

export const StyledTopBarButton = styled(Button)`
  position: relative;
  z-index: 2 !important;
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  background-color: ${(props) => {
    switch (props.intent) {
      case 'danger':
        return 'var(--bg-destructive, "#EF4444") !important;'
      default:
        return 'var(--bg-primary, "#00703D") !important;'
    }
  }};
  color: white !important;

  &:hover {
    background-color: ${(props) => {
    switch (props.intent) {
      case 'danger':
        return `${hexToRgba('#EF4444', 0.9)} !important;`
      default:
        return `${hexToRgba('#00703D', 0.9)} !important;`
    }
  }};
  }

  &:disabled {
    background-color: ${(props) => {
    switch (props.intent) {
      case 'danger':
        return `${hexToRgba('#EF4444', 0.5)} !important;`
      default:
        return `${hexToRgba('#00703D', 0.5)} !important;`
    }
  }};
  }
`;

const StyledTopBarDialog = styled(CartOrderEditEmailDialog)`
  position: relative;
  /* index required to be above dragger component */
  z-index: 2 !important;
`;

const StyledButtonGroup = styled.div`
  /* index required to be above dragger component */
  .bp5-button {
    position: relative;
    z-index: 2 !important;
  }
`;

const StyledRightButtonGroup = styled(StyledButtonGroup)`
  float: right;

  .bp5-button {
    margin-left: 5px;
  }
`;

const StyledNavbar = styled.div`
  padding: 8px 40px;
  background: var(--bg-secondary, #F4F4F5);
  box-shadow: 0px -10px 18px -10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.span`
  font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 28px;
color: var(--text-secondary, #535B67);
`

const CartOrderPreviewTopbar: React.FC<CartTopbarProps> = props => {
  const { t } = useTranslation();
  const dispatch = props.dispatch;
  const location = useLocation();
  const navigate = useNavigate();
  const onReorder = useCallback(
    () => {
      if (location.pathname.indexOf("/order") === 0) {
        navigate("/");
      }
      props.selectedOrder && dispatch(resetCartToOrder(props.selectedOrder, undefined))
    },
    [props.selectedOrder, dispatch]
  );
  const onEdit = useCallback(() => dispatch(setOrderEdit(true)), [dispatch]);
  const onEditCancel = useCallback(() => dispatch(setOrderEdit(false)), [
    dispatch
  ]);
  return (
    <>
      <StyledTitle>{t("DETAILS")}</StyledTitle>
      <Flex gap="12px">
        {props.mode === CartMode.orders && (
          <>
            <StyledTopBarButton
              intent={"primary"}
              disabled={!props.selectedOrder}
              onClick={onEdit}
              icon={EditIcon}
            >
              {t("EDIT")}
            </StyledTopBarButton>
            <StyledTopBarButton
              intent={"primary"}
              disabled={!props.selectedOrder}
              onClick={onReorder}
              icon={cartIcon}
            >
              {t("REORDER")}
            </StyledTopBarButton>
          </>
        )}
        {props.mode === CartMode.ordersEdit && (
          <>
            <StyledTopBarButton
              disabled={!props.selectedOrder}
              onClick={onEditCancel}
            >
              {t("CANCEL")}
            </StyledTopBarButton>
            <StyledTopBarDialog disableOnNoChanges={true} />
            <StyledTopBarDialog
              disableOnNoChanges={false}
              label={t("DELETE_ORDER")}
              icon={DeleteOrderIcon}
              intent="danger"
              cancelOrder={true}
            />
          </>
        )}
      </Flex>
    </>
  );
};

const StyledDialogOverlay = styled(StyledOverlay)`
  background-color: ${$lightGray4 + "DD"};
  backdrop-filter: none;
`;

const StyledCartheaderTitle = styled.div`
  display: flex;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: var(--text-primary, #535B67);
  gap: 12px;
  align-items: center;
`

const StyledNumberOfItems = styled.div`
 font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
`

const CartCurrentTopbar: React.FC<CartTopbarProps> = props => {
  const { t } = useTranslation();
  const dispatch = props.dispatch;
  const [isNameSelectOpen, setNameSelectOpen] = useState(false);
  useEffect(() => {
    if (!props.orderInProgress && isNameSelectOpen) {
      setNameSelectOpen(false);
    }
    // eslint-disable-next-line
  }, [props.orderInProgress]);
  useEffect(() => {
    if (isNameSelectOpen) {
      dispatch(setCartMinimizedAction(false));
      // reload availability
      if (isNameSelectOpen) {
        dispatch(reloadAvailability());
      }
    }
  }, [isNameSelectOpen, dispatch]);
  const [name, setName] = useState(props.order ? props.order.name : "");
  const [remark, setRemark] = useState(props.order ? props.order.remark : "");
  const c = props.numberOfItems || 0;
  return (
    <>
        <StyledCartheaderTitle>
          {t("CART")} 
          <StyledNumberOfItems>{t("ITEM_IN_CART", { count: c })}</StyledNumberOfItems>
        </StyledCartheaderTitle>
      <StyledRightButtonGroup>
        <StyledTopBarButton
          onClick={() => setNameSelectOpen(true)}
          disabled={props.isCartEmpty}
          intent="success"
          icon={cartIcon}
        >
          {t("ORDER")}
        </StyledTopBarButton>
        <StyledDialog
          title={t("CONFIRM_ORDER_TEXT")}
          isOpen={isNameSelectOpen}
          isCloseButtonShown={!props.orderInProgress}
          onClose={() => {
            if (!props.orderInProgress) {
              setNameSelectOpen(false);
            }
          }}
        >
          <div className={DIALOG_BODY} style={{ position: "relative" }}>
            <Label>
              {t("NAME_OF_ORDER")}
              <InputGroup
                value={name}
                disabled={props.orderInProgress}
                onChange={e => setName(e.currentTarget.value)}
                placeholder={t("NAME")}
              />
            </Label>
            <Label>
              {t("ORDER_REMARK")}
              <InputGroup
                value={remark}
                disabled={props.orderInProgress}
                onChange={e => setRemark(e.currentTarget.value.length > 255 ? e.currentTarget.value.substr(0, 255) : e.currentTarget.value)}
                placeholder={t("ORDER_REMARK")}
              />
            </Label>
            {props.orderInProgress && (
              <StyledDialogOverlay>
                <Spinner />
              </StyledDialogOverlay>
            )}
          </div>
          <div className={DIALOG_FOOTER}>
              <Flex justifyContent="space-between">
                <StyledButton
                  disabled={props.orderInProgress}
                  onClick={() => setNameSelectOpen(false)}
                >
                  {t("CANCEL")}
                </StyledButton>
                <StyledTopBarButton
                  disabled={name.length === 0 || props.orderInProgress}
                  onClick={() => {
                    props.dispatch(placeOrder(name, remark));
                    // the dialog will be closed when the placeOrder call completes
                  }}
                  intent="success"
                >
                  {t("ORDER")}
                </StyledTopBarButton>
              </Flex>
          </div>
        </StyledDialog>
      </StyledRightButtonGroup>
    </>
  );
};

const CartButtonWrapper = styled(Flex)`
  width: 100%;
  button {
    outline: none;
  }
`

const CartTopbar: React.FC<CartTopbarProps> = props => {
  const { t } = useTranslation()
  return (
    <Flex flexDirection="column">
      <CartButtonWrapper justifyContent="center">
        <BoxShadow>
          <StyledTopBarOpenButton
            intent={"primary"}
            onClick={() =>
              props.onToggleMinimized &&
              props.onToggleMinimized(!props.isMinimized)
            }
            icon={props.isMinimized ? chevronUp : chevronDown}
          >
            {props.isMinimized ? t("OPEN_CART_BAR") : t("CLOSE_CART_BAR")}
          </StyledTopBarOpenButton>
        </BoxShadow>
      </CartButtonWrapper>
      <StyledNavbar>
          {props.mode === CartMode.shop && <CartCurrentTopbar {...props} />}
          {(props.mode === CartMode.orders ||
            props.mode === CartMode.ordersEdit) && (
              <CartOrderPreviewTopbar {...props} />
            )}
      </StyledNavbar>
    </Flex>
  );
};

export default connect(mapStateToProps)(CartTopbar);
