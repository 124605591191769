import React, { useEffect, useState, useRef } from "react";
import { DroppableProvided } from "react-beautiful-dnd";
import styled from "styled-components";
import FlexBody, { StyledFlexBody } from "./FlexBody";
import FlexHeader, { StyledFlexHeaderRow } from "./FlexHeader";
import { FlexBoxRowRendererProps } from "./FlexRow";
import { Spinner } from "@blueprintjs/core";

// import { StickyContainer, Sticky } from "react-sticky";

const StyledTableContainer = styled.div<{ internalScroll?: boolean }>`
  position: relative;
  border: 1px solid var(--border, #E4E4E7);
  border-bottom: 0;
  color: var(--text-primary);

  &.internal-scroll {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    height: 100%;
    width: 100%;

    ${StyledFlexHeaderRow} {
      flex: 0 0 40px;
      overflow-y: scroll;
    }
  }
`;

const StyledDragWrapper = styled.div<{ internalScroll?: boolean }>`
  ${props =>
    props.internalScroll
      ? `overflow-y: scroll; min-height: 0; height: 100%;`
      : ""};
  /* overwrite default flex body */
  ${StyledFlexBody} {
    overflow-y: visible !important;
  }
`;

export const FlexBoxColumn: React.FC<FlexBoxColumnProps> = props => null;
export type FlexBoxCellContentRenderer<T> = (
  props: T
) => JSX.Element | string | null;

export interface FlexBoxColumnProps<T extends object = any> {
  children?: FlexBoxCellContentRenderer<{
    rowData: T;
    data: T[keyof T];
    rowIndex: number;
  }>;
  name: string | JSX.Element;
  dataKey?: keyof T;
  flex: string;
  minWidth?: number;
  maxWidth?: number;
  className?: string;
  align?: "flex-start" | "center" | "flex-end";
}

export interface FlexBoxTableProps<T extends object = any> {
  data: T[];
  striped?: boolean;
  selectionPredicate?: (T) => boolean;
  // preserves the place of a row while dragging
  keepRowSpaceWhenDragging?: boolean;
  droppableProvided?: DroppableProvided;
  draggable?: boolean;
  loading?: boolean;
  interactive?: boolean;
  noHeader?: boolean;
  internalScroll?: boolean;
  fixedHeaderRow?: boolean;
  className?: string;
  bodyClassName?: string;
  headerClassName?: string;
  draggableItemIdValue?: (rowData: T, index: number) => string;
  rowRenderer?: React.FC<FlexBoxRowRendererProps>;
  cellRenderer?: React.FC<FlexBoxColumnProps>;
  onRowClick?: (props: { index: number; rowData: T }) => void;
  onCellClick?: (props: {
    rowIndex: number;
    rowData: T;
    dataKey: string;
  }) => void;
  children?: React.ReactNode;
}

export function useTraceUpdate<T extends {}>(props: T, prefix: string = "") {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    // if (Object.keys(changedProps).length > 0) {
    //   console.log(prefix, "Changed props Table:", changedProps);
    // }
    prev.current = props;
  });
}

/**
 * FlexBoxTable
 * @param props
 */
const FlexBoxTable: React.FC<FlexBoxTableProps> = props => {
  const [columns, setColumns] = useState(
    getColumnPropsFromChildren(
      React.Children.toArray(props.children) as any
    ) as FlexBoxColumnProps[]
  );

  // useTraceUpdate(props);

  useEffect(() => {
    setColumns(
      getColumnPropsFromChildren(
        React.Children.toArray(props.children) as any
      ) as FlexBoxColumnProps[]
    );
  }, [props.children]);

  // if we have no children just render the container
  if (!props.children) {
    return <div className={props.className} />;
  }

  // console.log("Rendering shit Table...");

  const tableBody =
    (columns.length !== 0 && props.data.length) !== 0 ? (
      <FlexBody
        draggableId={props.draggableItemIdValue}
        internalScroll={props.internalScroll}
        className={props.bodyClassName}
        columns={columns}
        items={props.data}
        striped={props.striped}
        draggable={props.draggable}
        keepRowSpaceWhenDragging={props.keepRowSpaceWhenDragging}
        rowRenderer={props.rowRenderer}
        onRowClick={props.onRowClick}
        selectionPredicate={props.selectionPredicate}
      />
    ) : null;

  return (
    <StyledTableContainer
      internalScroll={props.internalScroll}
      className={
        (props.className ? props.className : "") +
        (props.internalScroll ? " internal-scroll" : "")
      }
    >
      {!props.noHeader && (
        <FlexHeader
          internalScroll={props.internalScroll}
          className={props.headerClassName}
          columns={columns}
        />
      )}
      {props.loading && (
        <>
          <Spinner />
        </>
      )}
      {props.droppableProvided ? (
        <StyledDragWrapper
          internalScroll={props.internalScroll}
          {...props.droppableProvided.droppableProps}
          ref={props.droppableProvided.innerRef}
        >
          {tableBody}
          {props.droppableProvided.placeholder}
          {!props.keepRowSpaceWhenDragging &&
            props.droppableProvided.placeholder}
        </StyledDragWrapper>
      ) : (
        tableBody
      )}
    </StyledTableContainer>
  );
};

export const ofType = <T extends object>() =>
  FlexBoxTable as React.FC<FlexBoxTableProps<T>>;

const getColumnPropsFromChildren = <T extends object>(
  children:
    | ReturnType<typeof FlexBoxColumn>
    | ReturnType<typeof FlexBoxColumn>[]
): FlexBoxColumnProps<T>[] => {
  if (!children) {
    return [];
  }
  return Array.isArray(children)
    ? children.map(child => child!.props).filter(child => child !== undefined)
    : [children.props];
};

export default FlexBoxTable;
