import "react-virtualized/styles.css";
import { Button, H4, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  Table,
  TableCellRenderer,
  TableHeaderRenderer,
  TableRowRenderer
} from "react-virtualized";
import styled from "styled-components";
import { localeStringConverter } from "../i18n";
import { SipShopCoreServicesVoOrder } from "../services/productCatalogue";
import { ReduxRootType } from "../store/store";

import { ConnectedComponent, defaultTimeFormat, hexToRgba } from "../utils";
import { arrowIcon, sortIcon, sortIconDown, sortIconUp } from "../assets/icons";

export const StyledTable = styled(Table)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-primary, #535B67);
  padding-bottom: 50px;
  .ReactVirtualized__Table__headerRow {
    font-weight: bold;
    /* add this for force constant spacing to content by also adding a scroll bar */
    overflow-y: scroll;
    width: auto;
    text-transform: none;
    background-color: var( --bg-secondary, #F4F4F5);
  }
  
  .ReactVirtualized__Table__headerColumn {
    border: 1px solid var(--border, #E4E4E7);
    width: 100%;
    height: 100%;
    margin: 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    box-sizing: border-box;
    border-right: 0;
    border-bottom: 0;
    display: flex;
    align-items: center;
    padding: 8px;
    outline: none;
    &:last-of-type {
      border-right:  1px solid var(--border, #E4E4E7);
    }
    line-height: 16px;
    .headerWrapper{
      width: 100%;
      display: flex;
      outline: none;
      justify-content: space-between;
      
    }
  }

  .ReactVirtualized__Table__rowColumn{
    padding: 8px;
    border: 1px solid var(--border, #E4E4E7);
    width: 100%;
    height: 100%;
    margin: 0px !important;
    border-bottom: 0;
    border-right: 0;
    display: flex;
    align-items: center;
    &:last-child {
      border-right: 1px solid var(--border, #E4E4E7);
    }
  }

  .ReactVirtualized__Table__row {
    cursor: pointer;
    background-color: #fff;
    &:last-child {
      border-bottom: 1px solid var(--border, #E4E4E7);
    }
    &:hover { 
      background-color: ${hexToRgba('#00703D', 0.1)};
    }

    &:focus {
      outline: none !important;
    }

    &.active {
      background-color: ${hexToRgba('#00703D', 0.1)};
      outline: none;
    }
  }
`;

const HeaderRowCell = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const mapStateToProps = (state: ReduxRootType) => ({});

interface OrderTableProps extends ConnectedComponent<typeof mapStateToProps> {
  loading?: boolean;
  omitColumns?: (keyof SipShopCoreServicesVoOrder)[];
  orders: SipShopCoreServicesVoOrder[];
  sortBy?: keyof SipShopCoreServicesVoOrder;
  sortDirection?: "ASC" | "DESC";
  onSort: (options: {
    sortBy: keyof SipShopCoreServicesVoOrder;
    sortDirection: "ASC" | "DESC";
  }) => void;
  selectedId?: string;
  widthInset?: number;
  heightInset?: number;
  onOrderSelected?: (order: SipShopCoreServicesVoOrder) => void;
  className?: string;
  tableProps?: Partial<{
    width: number;
    height;
    number;
    headerHeight: number;
    headerClassName: string;
  }>;
}
/**
 * creates a row renderer that handles selected rows
 * this will append active to the selected row
 * @param selectedId
 */
const orderRowRendererCreator = (
  selectedId?: string
): TableRowRenderer => props => {
  return defaultTableRowRenderer(
    (props.rowData as SipShopCoreServicesVoOrder).id === selectedId
      ? {
        ...props,
        className: props.className + " active"
      }
      : props
  );
};

export const StyledTableEmptyDiv = styled.div`
  opacity: 0.3;
  padding: 25px;
  text-align: center;
`;

export const StyledIconButton = styled(Button)`
  color: var(--bg-primary, "#00703D") !important;
  border: 1px solid var(--bg-primary, "#00703D");
  border-radius: 0px;
  background-color: white !important;
  width: 40px;
  height: 40px;
  outline: none;
  box-shadow: none !important;
`


const OrderTable: React.FC<OrderTableProps> = ({
  orders,
  sortBy,
  sortDirection,
  omitColumns = [],
  onOrderSelected,
  onSort,
  className,
  tableProps = {},
  widthInset = 0,
  heightInset = 0,
  selectedId,
  loading
}) => {
  const { t, i18n } = useTranslation();
  const [scrollIndex, setScrollIndex] = useState<number | undefined>();
  // on initial load scroll to selection
  useEffect(() => {
    if (!selectedId) {
      return;
    }
    // find item
    const itemIndex = orders.findIndex(order => order.id === selectedId);
    // scroll to item
    if (itemIndex !== -1) {
      setScrollIndex(itemIndex);
    }
  }, [selectedId, orders]);

  // create a localized date cell render
  const dateRenderer = useCallback(
    dateRendererCreator(localeStringConverter(i18n.language)),
    [i18n.language]
  );
  // create a new row renderer if selectedId changed
  const rowRenderer = useCallback(orderRowRendererCreator(selectedId), [
    selectedId
  ]);

  const onRowSelected = useCallback(
    (params: {
      rowData: SipShopCoreServicesVoOrder;
      index: number;
      e: SyntheticEvent;
    }) => {
      if (!onOrderSelected) {
        return;
      }
      onOrderSelected(params.rowData);
      setScrollIndex(params.index);
    },
    [onOrderSelected]
  );

  const onSortCallback = useCallback(onSort && (options => onSort(options)), [
    onSort
  ]);

  // check if column requires render
  const showColumn = (key: keyof SipShopCoreServicesVoOrder) => {
    return omitColumns.findIndex(col => col === key) === -1;
  };
  return (
    <>
      <AutoSizer>
        {({ width, height }) => (
          <StyledTable
            sort={onSortCallback}
            onRowClick={onRowSelected}
            rowRenderer={rowRenderer}
            sortBy={sortBy}
            noRowsRenderer={() =>
              loading ? (
                <Spinner />
              ) : (
                <StyledTableEmptyDiv>
                  <Icon icon="th-disconnect" size={40} />
                  <H4>{t("NO_ORDERS")}</H4>
                </StyledTableEmptyDiv>
              )
            }
            sortDirection={sortDirection}
            width={width - widthInset}
            height={height - heightInset}
            headerHeight={50}
            rowHeight={50}
            scrollToIndex={scrollIndex}
            scrollToAlignment="auto"
            rowCount={orders.length}
            headerClassName="header"
            rowGetter={({ index }) => orders[index]}
            className={className}
            {...tableProps}
          >
            {showColumn("statusUpdated") && (
              <Column
                headerRenderer={headerRenderer}
                width={100}
                label={t("DATE")}
                cellRenderer={dateRenderer}
                dataKey="statusUpdated"
              />
            )}
            {showColumn("name") && (
              <Column
                headerRenderer={headerRenderer}
                minWidth={140}
                flexGrow={0.3}
                width={140}
                label={t("NAME")}
                dataKey="name"
              />
            )}

            {showColumn("remark") && (
              <Column
                headerRenderer={headerRenderer}
                minWidth={140}
                flexGrow={0.3}
                width={140}
                label={t("ORDER_REMARK")}
                dataKey="remark"
              />
            )}

            {showColumn("sapOrderId") && (
              <Column
                headerRenderer={headerRenderer}
                width={220}
                flexGrow={0}
                label={t("ORDER_NUMBER")}
                dataKey="sapOrderId"
              />
            )}
            {showColumn("id") && (
              <Column
                headerRenderer={headerRenderer}
                width={200}
                flexGrow={0}
                label={t("ORDER_NR")}
                dataKey="id"
              />
            )}
            {showColumn("deliveryDate") && (
              <Column
                headerRenderer={headerRenderer}
                width={130}
                label={t("DELIVERY_DATE")}
                cellRenderer={dateRenderer}
                dataKey="deliveryDate"
              />
            )}
            {showColumn("deliveryAddress") && (
              <Column
                headerRenderer={headerRenderer}
                cellRenderer={labeledDataRenderer}
                width={120}
                flexGrow={0.4}
                label={t("RECIPIENT_ADDRESS")}
                dataKey="deliveryAddress"
              />
            )}
            {showColumn("status") && (
              <Column
                headerRenderer={headerRenderer}
                width={120}
                minWidth={120}
                cellRenderer={deliveryStateRenderer}
                label={t("DELIVERY_STATE")}
                dataKey="status"
              />
            )}
            {showColumn("cntDocuments") && (
              <Column
                headerRenderer={headerRenderer}
                width={120}
                label={t("DOCUMENTS")}
                cellRenderer={documentRenderer}
                dataKey="cntDocuments"
              />
            )}
            {showColumn("owner") && (
              <Column
                headerRenderer={headerRenderer}
                width={150}
                label={t("EDITOR")}
                cellRenderer={labeledDataRenderer}
                dataKey="owner"
              />
            )}
            <Column
              headerRenderer={() => <></>}
              width={60}
              cellRenderer={() => <StyledIconButton aria-label="Details">{arrowIcon}</StyledIconButton>}
              dataKey=""
            />
          </StyledTable>
        )}
      </AutoSizer>
    </>
  );
};

export default connect(mapStateToProps)(OrderTable);

export const TooltipTableWrapper = styled.div`
width: 100%;
  .bp5-popover-content {
    max-width: 300px;
    background: white !important;
    color: var(--text-primary, #535B67) !important;
  }

  .bp5-popover-target{
    width: 100%;
  }
`

/**
 * Header cell renderer
 * @param props
 */
export const headerRenderer: TableHeaderRenderer = props => (
  <TooltipTableWrapper><Tooltip content={<>{props.label}</>} popoverClassName="tooltip-popover" minimal={true}>
  <div className="headerWrapper">
    <HeaderRowCell
      style={
        props.dataKey === props.sortBy ? { color: "#0A6640" } : undefined
      }
    >
      {props.label}
    </HeaderRowCell>
    {props.dataKey === props.sortBy  ? (
      props.sortDirection === "DESC" ? sortIconDown : sortIconUp
    ) :
      !props.disableSort && sortIcon
    }
  </div>
  </Tooltip>
  </TooltipTableWrapper>
);

/**
 * renders content of a cell with labeled data content
 * @param props
 */
const labeledDataRenderer: TableCellRenderer = props => props.cellData.label;

/**
 * creates a localized renderer for a date cell
 * @param locale
 */
const dateRendererCreator = (locale: string): TableCellRenderer => props =>
  props.cellData
    ? typeof props.cellData === "string"
      ? new Date(parseInt(props.cellData) * 1000).toLocaleDateString(
        locale,
        defaultTimeFormat
      )
      : new Date(props.cellData * 1000).toLocaleDateString(
        locale,
        defaultTimeFormat
      )
    : "";

const StyledStatusLink = styled.a`
     color: var(--text-primary, "#535B67");
     text-decoration: underline;
     &:hover {
      color: var(--text-primary, "#535B67");
     }
    `

/**
 * Delivery Status Table Cell
 * @param props
 */
const deliveryStateRenderer: TableCellRenderer = props => {
  return <StyledStatusLink href="https://siportal.sip.de/">SIPortal.sip.de</StyledStatusLink>
};

const StyledDocumentNumber = styled.div<{ number: string }>`
  padding: 2px 8px;
  ${props =>
    props.number === "0" ? `background: var(--bg-secondary, #F4F4F5);` : `background: rgba(0, 107, 128, 0.20);`}
`

const documentRenderer: TableCellRenderer = props => {
  const data = props.cellData as Number;
  return <StyledDocumentNumber number={data.toString()}>{data.toString()}</StyledDocumentNumber>
};
