import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

import { Provider } from "react-redux";
import { storeCreator } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

import { createGlobalStyle } from "styled-components";

// Add global styles directly
const GlobalStyles = createGlobalStyle`
  body {
    position: fixed;
    overflow: hidden;
    margin: 0;
    min-width: 800px;
    font-family: 'Roboto Condensed',  BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .product-image-dialog {
    width: 80vh !important;
    background-color: #fff;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`;

// render app and inject Redux
const { store, persistor } = storeCreator();

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <GlobalStyles />
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// log current version
if (process.env.NODE_ENV === "production") {
  console.log(
    "Production Build. Build at " +
    new Date(
      (process.env.REACT_APP_BUILD_AT
        ? parseInt(process.env.REACT_APP_BUILD_AT)
        : 0) * 1000
    ) +
    " (commit " +
    process.env.REACT_APP_GIT_SHA +
    ")"
  );
} else {
  console.log("Running app in development mode...");
}
