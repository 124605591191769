import { Button, Checkbox, Label } from "@blueprintjs/core";
import { DARK, INLINE } from "@blueprintjs/core/lib/esm/common/classes";
import { DateInput3 } from "@blueprintjs/datetime2";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/cs";
// Make sure moment.js has the required locale data
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/hu";
import "moment/locale/it";
import "moment/locale/pl";
import "moment/locale/ro";
import "moment/locale/ru";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import AddressSelect from "../../components/AddressSelect";
import { localeStringConverter } from "../../i18n";
import { CustomDecorSelect } from "../../pages/shop/CustomDecors";
import {
  CurrentCart,
  SipShopCoreServicesVoCartEnv,
  SipShopCoreServicesVoLabeledData
} from "../../services/productCatalogue";
import {
  debouncedUpdateCartState,
  updateCartState
} from "../../store/actions/cart";
import { OrderModificationState, setOrderModValue } from "../../store/reducers/order";
import { ReduxRootType } from "../../store/store";
import {
  ConnectedComponent,
  dateFromUnixTimestampString,
  defaultTimeFormat,
  getUnixTimestampt
} from "../../utils";
import { CartMode } from "../Cart";
import { StyledCheckbox } from "../../pages/orders/Orders";
import { Flex } from "../Flex/Flex";
import { calendarIcon } from "../../assets/icons";

const StyledInputSubtitle = styled.small`
  padding-bottom: 15px;
  padding-left: 4px;
  display: inline-block;
`;

const InfoBlock = styled.div`
  display: inline-block;
  padding-right: 10px;
  &:last-child {
    padding-right: 0;
  }
  text-align: left;
`;

const StyledInfoBlockSpan = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  padding: 0px;
  gap: 40px;
`;

export const StyledLabel = styled(Label)`
  font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
color: var(--text-primary, #535B67);
`

const StyledTopBar = styled.div`
  color: var(--text-primary, "#535B67");
  &::after {
    content: "";
    clear: both;
    display: table;
    clear: both;
  }

  .bp5-input-group{
    padding: 8px 12px; 
    border: 1px solid var(--border, #E4E4E7) !important;
  }

  .bp5-date-input .bp5-input {
    
    border: 0 !important;
    background: white !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0px !important;
    color: var(--text-primary, #535B67) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    &:disabled {
      opacity: 0.5;
    }
  }

  .bp5-control.bp5-checkbox.bp5-disabled .bp5-control-indicator {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(16, 22, 26, 0.35) !important;
  }
`;

const DateInputWrapper = styled.div`
  color: var(--text-primary, #535B67) !important;

  input:disabled + .bp5-input-action {
  opacity: 0.5; 
}

  .bp5-html-select select {
    color: var(--text-primary, #535B67) !important;
}

    .bp5-input-action{
      padding: 16px 12px !important;
      pointer-events: none;
    }

    .bp5-button{
      color: var(--text-primary, #535B67) !important;
    }

    .rdp-caption_label{
      display: none;
    }
    .rdp-caption{
      display: flex;
      justify-content: space-between;
    }
    .rdp-caption_dropdowns{
      display: flex;
    }
    .bp5-datepicker-month-select{
    .bp5-icon {
      right: 30px !important;
    }
  }
`

const getShortLocaleCode = (locale: string) => {
  const localeParts = locale.split('_');
  return localeParts[0];
};

const mapStateToProps = (state: ReduxRootType) => ({
  env: state.cart.env,
  modifications: state.orders.modifications
});

interface OrderInfoBarProps
  extends ConnectedComponent<typeof mapStateToProps, Partial<CurrentCart>> {
  isVertical?: boolean;
  mode: CartMode;
}

const parseData = (str: string, locale?: string) => moment(str).toDate();
const formatDate = (date: Date, locale?: string) =>
  date.toLocaleDateString(
    locale ? localeStringConverter(locale) : undefined,
    defaultTimeFormat
  );

const OrderInfoBar: React.FC<OrderInfoBarProps> = props => {
  const { t } = useTranslation();

  const selectedData =
    props.modifications && props.modifications.deliveryDate
      ? "" + props.modifications.deliveryDate
      : props.mode === CartMode.shop
        ? props.favouredDeliveryDate
        : props.deliveryDate;

  let deliveryDate =
    selectedData && selectedData !== "0"
      ? dateFromUnixTimestampString(selectedData)
      : new Date();

  let minDate = props.mode === CartMode.shop ? new Date() : undefined;

  if (props.mode === CartMode.shop && deliveryDate < new Date()) {
    deliveryDate = new Date();
  }

  const language = getShortLocaleCode(i18next.language).replace("cz", "cs");

  const handleAddressSapId = (
    env: SipShopCoreServicesVoCartEnv | null,
    modifications: OrderModificationState | undefined,
    address: SipShopCoreServicesVoLabeledData | undefined,
  ): string => {
    if (props.env && props.env.deliveryAddresses.length > 0) {
      if (props.modifications && props.modifications.deliveryAddress) {
        for (let i = 0; i < props.env.deliveryAddresses.length; i++) {
          if (props.env.deliveryAddresses[i].id === props.modifications.deliveryAddress.data) {
            return props.env.deliveryAddresses[i].sapId;
          }
        }
      }
      if (props.deliveryAddress) {
        for (let i = 0; i < props.env.deliveryAddresses.length; i++) {
          if (props.env.deliveryAddresses[i].id === props.deliveryAddress.data) {
            return props.env.deliveryAddresses[i].sapId;
          }
        }
      }
    }
    return '';
  };
  const [addressSapId, setAddressSapId] = useState(handleAddressSapId(props.env, props.modifications, props.deliveryAddress));

  useEffect(() => {
    let updSapId = handleAddressSapId(props.env, props.modifications, props.deliveryAddress);
    if (updSapId !== addressSapId) {
      setAddressSapId(updSapId);
    }
  }, [props.env, props.modifications, props.deliveryAddress]);

  return (
    <StyledTopBar>
      <StyledInfoBlockSpan>
        <Flex flex="1 1 0" flexDirection="column">
          <StyledLabel>
            <span>{t("CONSIGNEE")} {addressSapId}</span>
          </StyledLabel>
          <InfoBlock>
            {props.deliveryAddress ? (
              <AddressSelect
                disabled={props.mode === CartMode.orders}
                items={props.env ? props.env.deliveryAddresses : []}
                selectionId={
                  props.modifications && props.modifications.deliveryAddress
                    ? props.modifications.deliveryAddress.data
                    : props.deliveryAddress
                      ? props.deliveryAddress.data
                      : null
                }
                onSelect={item =>
                  props.mode === CartMode.shop
                    ? props.dispatch(
                      updateCartState(
                        "deliveryAddress",
                        item ? { data: item.id } : null
                      )
                    )
                    : props.dispatch(
                      setOrderModValue({
                        deliveryAddress:
                          item &&
                            props.deliveryAddress &&
                            props.deliveryAddress.data !== item.id
                            ? { data: item.id, label: item.addressText }
                            : undefined
                      })
                    )
                }
              />
            ) : (
              ""
            )}
          </InfoBlock>
        </Flex>
        <Flex flex="1 1 0" flexDirection="column">
          <CustomDecorSelect
            selectedItem={
              props.modifications && props.modifications.deliveryType
                ? props.modifications.deliveryType
                : props.deliveryType
            }
            disabled={props.mode === CartMode.orders}
            label={t("ORDER_TYPE")}
            items={props.env ? props.env.deliveryTypes : []}
            onSelect={item =>
              props.mode === CartMode.shop
                ? props.dispatch(updateCartState("deliveryType", item))
                : props.dispatch(
                  setOrderModValue({
                    deliveryType: item ? item : undefined
                  })
                )
            }
          />
        </Flex>
        <Flex flex="1 1 0" flexDirection="column">
          <StyledLabel>
            <span>{t("DELIVERY_DATE_WISH")}</span>
          </StyledLabel>
          <DateInputWrapper>
            <DateInput3
              // maxDate is 32-bit date limit otherwise server will return negative timestamps
              maxDate={moment(new Date(2147000000000)).toDate()}
              minDate={minDate}
              locale={language}
              parseDate={parseData}
              formatDate={formatDate}
              rightElement={calendarIcon}
              inputProps={{"aria-label": "Delivery"}}
              onChange={date =>
                date &&
                (props.mode === CartMode.shop
                  ? props.dispatch(
                    debouncedUpdateCartState(
                      "favouredDeliveryDate",
                      getUnixTimestampt(new Date(date))
                    )
                  )
                  : props.dispatch(
                    setOrderModValue({
                      deliveryDate: getUnixTimestampt(new Date(date))
                    })
                  ))
              }
              value={deliveryDate.toDateString()}
              disabled={props.mode === CartMode.orders}
              popoverProps={{position: "bottom-left", minimal: true, usePortal: false}}
            />
          </DateInputWrapper>
        </Flex>

        <Flex flex="1 1 0" flexDirection="column">
          <StyledCheckbox
            checked={
              props.modifications &&
                props.modifications.completeDelivery !== undefined
                ? props.modifications.completeDelivery
                : !!props.completeDelivery
            }
            disabled={props.mode === CartMode.orders}
            name="COMPLETE_DELIVERY"
            label={t("COMPLETE_DELIVERY")}
            onChange={e => {
              props.dispatch(
                props.mode === CartMode.shop
                  ? debouncedUpdateCartState(
                    "completeDelivery",
                    !props.completeDelivery
                  )
                  : setOrderModValue({
                    completeDelivery: !(props.modifications &&
                      props.modifications.completeDelivery !== undefined
                      ? props.modifications.completeDelivery
                      : !!props.completeDelivery)
                  })
              );
            }}
          />

          <StyledInputSubtitle>
            {t("COMPLETE_DELIVERY_DESC")}<br />{t("COMPLETE_DELIVERY_DATE_HINT")}
          </StyledInputSubtitle>
        </Flex>
      </StyledInfoBlockSpan>
    </StyledTopBar >
  );
};

export default connect(mapStateToProps)(OrderInfoBar);
