export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS';

export enum ErrorType {
  loginFailed = 'loginFailed',
  setLocaleFailed = 'setLocaleFailed',
  getSeriesFailed = 'getSeriesFailed',
  loadSeriesTree = 'loadSeriesTree',
  searchFailed = 'searchFailed',
  loadCart = 'loadCart',
  access = 'access',
  getProduct = 'getProduct',
  getProductGroup = 'getProductGroup',
  updateCart = 'updateCart',
  placeOrder = 'placeOrder',
  addToCart = 'addToCart',
  loadOrderDetail = 'loadOrderDetail',
  loadOrders = 'loadOrders',
  exportCart = 'exportOrder',
  uploadCSVError = 'uploadCSVError',
  forgotPasswordFailed = 'forgotPasswordFailed',
  modifyOrderFailed = 'modifyOrderFailed',
  getImportHistory = 'getImportHistory',
  startImport = 'startImport',
  importDump = 'importDump',
  getCompanyUsers = 'getCompanyUsers',
  toggleUsers = 'toggleUsers',
  getCompanies = 'getCompanies',
  getCompanyAddresses = 'getCompanyAddresses',
  toggleAddresses = 'toggleAddresses',
  unknown = 'unknown'
}

export interface SetErrorAction {
  err: ErrorMsg;
  type: typeof SET_ERROR;
  errType: ErrorType;
}
export interface ClearAllErrorsAction {
  type: typeof CLEAR_ALL_ERRORS;
}
export interface ClearErrorAction {
  type: typeof CLEAR_ERROR;
  errType: ErrorType;
}

export interface ErrorMsg {
  msg: string;
}

export type ErrorState = {
  [key: string]: ErrorMsg;
};

export const setErrorAction = (errType: ErrorType, err: ErrorMsg) =>
    ({type: SET_ERROR, errType, err});
export const clearErrorAction = (errType: ErrorType) =>
    ({type: CLEAR_ERROR, errType});
export const clearAllErrorsAction = () => ({type: CLEAR_ALL_ERRORS});

export type ActionsUser =|SetErrorAction|ClearErrorAction|ClearAllErrorsAction;

const errorReducer = (state: ErrorState = {}, action: ActionsUser) => {
  switch (action.type) {
    case SET_ERROR:
      const newState = {...state};
      newState[action.errType] = action.err;
      return newState;
    case CLEAR_ALL_ERRORS:
      return {};
    case CLEAR_ERROR:
      let ns = {...state};
      delete ns[action.errType];
      return ns;
  }
  return state;
};

export default errorReducer;
