import React, { PropsWithChildren } from 'react';
import { FlexStyled, FlexStyleProps } from './Flex.styled';

export type FlexProps = PropsWithChildren<
  FlexStyleProps & {
    className?: string;
    style?: React.CSSProperties;
  }
>;

/**
 * Use the Flex component to create `flexbox` layouts.
 */
const InternalFlex: React.ForwardRefRenderFunction<
  HTMLDivElement,
  FlexProps
> = (props, ref) => <FlexStyled {...props} ref={ref} />;

export const Flex = React.forwardRef(InternalFlex);
Flex.displayName = 'Flex';
