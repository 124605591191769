import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import cz from './locale/cz_CZ.json';
import de from './locale/de_DE.json';
import en from './locale/en_GB.json';
import es from './locale/es_ES.json';
import fr from './locale/fr_FR.json';
import hu from './locale/hu_HU.json';
import it from './locale/it_IT.json';
import pl from './locale/pl_PL.json';
import pt from './locale/pt_PT.json';
import ro from './locale/ro_RO.json';
import ru from './locale/ru_RU.json';

const browserToLocaleString =
    () => {
      const locale = window.navigator.language.replace('-', '_')
      for (var prop in resources) {
        if (Object.prototype.hasOwnProperty.call(resources, prop)) {
          // check for direct match
          if (locale === prop) {
            return prop
          }
          // check for indirect match (this ignores possible best choices but
          // only one language per region is provided so it is of no difference)
          if (prop.indexOf(locale) !== -1) {
            return prop
          }

          // check for matches without region
          const localeSplit = locale.split('_')
          if (localeSplit[0] && prop.indexOf(localeSplit[0]) !== -1) {
            return prop
          }
        }
      }
      // return german as the default
      return 'de_DE'
    }

// the translations
const resources = {
  cz_CZ: {translation: cz},
  de_DE: {translation: de},
  en_GB: {translation: en},
  es_ES: {translation: es},
  fr_FR: {translation: fr},
  hu_HU: {translation: hu},
  it_IT: {translation: it},
  pl_PL: {translation: pl},
  pt_PT: {translation: pt},
  ro_RO: {translation: ro},
  ru_RU: {translation: ru}
};

i18n.use(initReactI18next)  // passes i18n down to
                            // react-i18next
    .init({
      resources,
      lng: browserToLocaleString(),
      fallbackLng: "en_GB",
      keySeparator: false,  // we do not use keys in form
      // messages.welcome

      interpolation: {
        escapeValue: false  // react already safes from xss
      }
    });

/**
 * Converts app locale strings to browser locale string
 * xx_XX -> xx-XX
 */
export const localeStringConverter = (locale: string) =>
    locale.replace('_', '-');


export default i18n;
