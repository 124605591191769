import {
  Button,
  Classes,
  Navbar,
  Popover,
  Position
} from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import logo from "./assets/images/sip-logo.svg";
import CartButton from "./components/CartButton";
import LanguageDropdown from "./components/LanguageDropdown";
import UserMenu from "./components/UserMenu";
import { LanguageCode, setLanguage } from "./store/actions/user";
import { ProductSelectionState } from "./store/reducers/shop";
import { ReduxRootType } from "./store/store";
import { ConnectedComponent } from "./utils";
import { Flex } from "./components/Flex/Flex";
import HelpMenu from "./components/HelpMenu";

export const getSelectionURL = (selection?: ProductSelectionState) =>
  selection
    ? `/${selection.item ? "product" : "product-group"}/${selection.item ? selection.item.id : selection.group.id
    }`
    : "/";

const mapStateToProps = (state: ReduxRootType) => ({
  isLoggedIn: state.user !== null && state.user.id !== "",
  isAdmin: state.user !== null && state.user.isAdmin,
  companyName: state.user !== null ? state.user.companyName : "",
  cartItems:
    state.cart.current && state.cart.current.items
      ? state.cart.current.items
      : [],
  selectionURL: getSelectionURL(state.shop.selection),
  isShopES: state.user !== null && state.user.country === "ES"
});

interface HeaderProps extends ConnectedComponent<typeof mapStateToProps> { }

export const StyledNavLink = styled(NavLink)`

  &.active button {
    background-color: #F4F4F5;
    transition: all ease-in 0.5;
    outline: none !important;
  }
  &.active button .bp5-button-text {
    color: #00703D;
  }
`;


const shopPathMatcher = (match, location): boolean => {
  return (
    location.pathname.indexOf("product-group/") !== -1 ||
    location.pathname.indexOf("product/") !== -1 ||
    location.pathname === "/"
  );
};

const Header = (props: HeaderProps) => {
  const { t } = useTranslation();
  // const [isVideoOpen, setVideoGuideOpen] = useState(false);
  const isLoggedIn = props.isLoggedIn;

  return (
    <header className="app-header">
      <Navbar>
        <Flex gap="80px">
          <NavLink to={props.selectionURL}>
            <img alt="Salamander" src={logo} />
          </NavLink>
          {isLoggedIn && (
            <Flex alignItems="center" gap="12px">
              <StyledNavLink to={props.selectionURL}>
                <Button
                  className={Classes.MINIMAL}
                  text={t("SHOP")}
                />
              </StyledNavLink>
              <StyledNavLink to={`/orders`}>
                <Button
                  className={Classes.MINIMAL}
                  text={t("ORDERS")}
                />
              </StyledNavLink>
             {props.isAdmin && <StyledNavLink to={`/admin`}>
                <Button
                  className={Classes.MINIMAL}
                  text="Admin"
                />
              </StyledNavLink>}
              <HelpMenu isShopES={props.isShopES} />
            </Flex>
          )}
        </Flex>
        <Flex alignItems="center">
          <LanguageDropdown
            onLanguageChanged={lang => props.dispatch(setLanguage(lang))}
          />
          {isLoggedIn && (
            <>
              <CartButton />
              <UserMenu />
            </>
          )}
        </Flex>
      </Navbar>
    </header>
  );
};

export default connect(mapStateToProps)(Header);

/**
 * Utils
 */

// opens manual in new tab
const openUserManual = (language: LanguageCode) => {
  switch (language) {
    case LanguageCode.DE:
    case LanguageCode.PL:
    case LanguageCode.EN:
      window.open(`/guides/SIPLeitfaden_${language}.pdf`);
      return;
    default:
      window.open(`/guides/SIPLeitfaden_${LanguageCode.EN}.pdf`);
      return;
  }
};

// opens color booklet in new tab
const openColorBooklet = (isShopES: boolean, language: LanguageCode) => {
  if (isShopES) {
    if (language === LanguageCode.PT) {
      window.open(`/online-shop/decors_colours_pt.pdf`);
    } else {
      window.open(`/online-shop/decors_colours_es.pdf`);
    }
  } else {
    window.open(`/online-shop/decors_colours.pdf`);
  }
  return;
};

// guide menu dropdown
// DISABELD: since only one guide element is currently present
// const GuideMenu: React.FC<{ onOpenVideo: () => void }> = props => {
//   const { t, i18n } = useTranslation();
//   return (
//     <Menu>
//       <MenuItem
//         onClick={() => openUserManual(i18n.language as LanguageCode)}
//         icon={
//           <img
//             style={{ height: 20 }}
//             alt={t("VIDEOGUIDE")}
//             src={"/images/benutzerhandbuch.jpg"}
//           />
//         }
//         text={t("USER_MANUAL")}
//       />
//       <MenuItem
//         onClick={props.onOpenVideo}
//         icon={
//           <img
//             style={{ height: 20 }}
//             alt={t("VIDEOGUIDE")}
//             src={"/images/Videoleitfaden-1.jpg"}
//           />
//         }
//         text={t("VIDEOGUIDE")}
//       />
//     </Menu>
//   );
// };
