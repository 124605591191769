import React, { CSSProperties } from "react";
import styled from "styled-components";

import { StyledFlexItem } from "./FlexCell";
import { StyledFlexRow } from "./FlexRow";
import { FlexBoxColumnProps } from "./FlexTable";
import { TooltipTableWrapper } from "../OrderTable";
import { Tooltip } from "@blueprintjs/core";

export const StyledFlexHeaderRow = styled(StyledFlexRow) <{ scroll?: boolean }>`
 background: var(--bg-secondary, #F4F4F5) !important;
  font-weight: bold;
  color: var(--text-primary, #535B67);
  /* add this for force constant spacing to content by also adding a scroll bar */
  border-width: 0;

  ${props =>
    props.scroll
      ? `
    min-height: 35px; overflow-y: scroll;
  `
      : ""}
`;

/**
 * Tooltip that is 100% of parents width and overflows with ellipsis
 */
export const StyledOverflowFlexTooltip = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;

`;
const FlexHeader: React.FC<{
  className?: string;
  columns: FlexBoxColumnProps[];
  internalScroll?: boolean;
  style?: CSSProperties;
}> = props => (
  <StyledFlexHeaderRow
    style={props.style}
    scroll={props.internalScroll}
    className={props.className}
  >
    {props.columns.map((columnProps, index) => (
      <StyledFlexItem
        key={index}
        flex={columnProps.flex}
        align={columnProps.align}
        minWidth={columnProps.minWidth}
        maxWidth={columnProps.maxWidth}
        className={columnProps.className}
      >
        <TooltipTableWrapper>
          <Tooltip content={<>{columnProps.name}</>} popoverClassName="tooltip-popover" minimal={true}>
        <StyledOverflowFlexTooltip >
          <>{columnProps.name}</>
        </StyledOverflowFlexTooltip>
        </Tooltip>
        </TooltipTableWrapper>
      </StyledFlexItem>
    ))}
  </StyledFlexHeaderRow>
);

export default FlexHeader;
