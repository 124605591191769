import { Button, Checkbox, InputGroup } from "@blueprintjs/core";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "react-virtualized/styles.css";
import styled from "styled-components";
import { Cart, CartMode } from "../../components/Cart";
import OrderTable from "../../components/OrderTable";
import SplitPage from "../../components/SplitPage";
import { loadOrders, searchOrderForMatId } from "../../store/actions/order";
import bottomBarReducer, {
  initialState,
  setCartMinimizedAction,
  setCartSizeAction
} from "../../store/reducers/bottomCart";
import {
  selectOrder,
  setOrdersQuery,
  setOrdersSort
} from "../../store/reducers/order";
import { ReduxRootType } from "../../store/store";
import { ConnectedComponent, debounce } from "../../utils";
import { searchIcon } from "../../assets/icons";

const StyledOrdersPage = styled.main`
  padding: 25px 50px 0px 50px;
  margin-left: 0;
  gap: 12px;
  display: flex;
  flex-direction: column;

  > h1 {
    color: var(--text-primary, "#535B67");
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.9px;
    margin: 0;
  }
`;


const StyledPrimarySearch = styled(InputGroup)`
  width: 50%;
  max-width: 550px;
  display: inline-block;
  border: 1px solid var(--border, #E4E4E7);
  >input {
    box-shadow: none !important ;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
   width: 100%;
   font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: var(--text-primary, #535B67);
  .bp5-control-indicator {
    box-shadow: none !important ;
    outline:  none !important;
    border: 1px solid var(--text-primary, #535B67);
    background: none !important;
    border-radius: 0px !important;
  }

  input:checked + .bp5-control-indicator::before{
      background-color: var(--bg-primary, #00703D);
      top: -1px;
      left: -1px;
      width: 16px;
      height: 16px;
      position: relative;
  }

`;

const mapStateToProps = (state: ReduxRootType) => ({
  isEditMode: state.orders.editMode,
  orders: state.orders.filter
    ? state.orders.filter.results
    : state.orders.entries,
  sortBy: state.orders.sort && state.orders.sort.key,
  sortDirection: state.orders.sort && state.orders.sort.direction,
  selectedOrder: state.orders.detail,
  query: state.orders.filter ? state.orders.filter.query : undefined
});

interface OrderPageProps extends ConnectedComponent<typeof mapStateToProps> {
  layout?: "horizontal" | "vertical";
}

const OrdersPage: React.FC<OrderPageProps> = props => {
  const { t } = useTranslation();
  const dispatch = props.dispatch;
  useEffect(() => {
    dispatch(loadOrders());
  }, [dispatch]);
  const [bottomBarState, bottomBarDispatch] = useReducer(
    bottomBarReducer,
    initialState
  );
  const [searchArticleNumber, setSearchArticleNumber] = useState(false)
  const deboucedUpdate = useCallback(
    debounce(
      (val: string, searchArticleNumber: boolean) => searchArticleNumber ?  props.dispatch(searchOrderForMatId(val)) : props.dispatch(setOrdersQuery(val)), 100),
    []
  );
  return (
    <SplitPage
      minimized={bottomBarState.minimized}
      size={bottomBarState.size}
      onSetSize={val => bottomBarDispatch(setCartSizeAction(val))}
    >
      <StyledOrdersPage className="page-content">
        <h1>{t("ORDERS")}</h1>
        <StyledPrimarySearch
          defaultValue={props.query}
          large
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            deboucedUpdate(e.currentTarget.value, searchArticleNumber)
          }
          aria-label={t("DO_SEARCH")}
          placeholder={t("DO_SEARCH")}
          leftIcon={<span aria-hidden="true" className="bp5-icon bp5-icon-search">{searchIcon}</span>}
        />
        <StyledCheckbox 
          onChange={
            () => {
              setSearchArticleNumber(!searchArticleNumber)
              deboucedUpdate(props.query ?? "", !searchArticleNumber)
            }
          } 
          checked={searchArticleNumber}>
          {t("ORDER_PRODUCT_SEARCH")}
        </StyledCheckbox>
        <OrderTable
          loading={props.orders === null}
          selectedId={props.selectedOrder ? props.selectedOrder.id : undefined}
          omitColumns={["id"]}
          onOrderSelected={order => {
            dispatch(selectOrder(order.id));
            if (bottomBarState.minimized) {
              bottomBarDispatch(setCartMinimizedAction(false));
            }
          }}
          heightInset={200}
          orders={props.orders ? props.orders : []}
          sortBy={props.sortBy}
          sortDirection={props.sortDirection}
          onSort={options =>
            dispatch(setOrdersSort(options.sortBy, options.sortDirection))
          }
        />
      </StyledOrdersPage>
      <Cart
        mode={props.isEditMode ? CartMode.ordersEdit : CartMode.orders}
        dispatch={props.dispatch}
        isMinimized={bottomBarState.minimized}
        onToggleMinimized={val =>
          bottomBarDispatch(setCartMinimizedAction(val))
        }
        order={props.selectedOrder ? props.selectedOrder : undefined}
      />
    </SplitPage>
  );
};

export default connect(mapStateToProps)(OrdersPage);
