import {
  InputGroup,
  Label
} from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER,
} from "@blueprintjs/core/lib/esm/common/classes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { forgotPassword } from "../store/actions/user";
import { ReduxRootType } from "../store/store";
import { ConnectedComponent, isValidEmail } from "../utils";
import styled from "styled-components";
import { Flex } from "./Flex/Flex";
import { StyledButton } from "./Cart/CornerBar";
import { StyledTopBarButton } from "./Cart/CartTopbar";
import { StyledDialog } from "../pages/home/LoginForm";

const mapStateToProps = (state: ReduxRootType) => ({});

interface ForgotPasswordDialogProps
  extends ConnectedComponent<typeof mapStateToProps> {
  label?: string;
  title?: string;
}

const StyledForgotPassword = styled.a`
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
text-decoration-line: underline;
color: var(--text-muted, "#7B818A");
margin-bottom: 24px;
&:hover {
  color: var(--text-muted, "#7B818A");
}
`

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setMail] = useState("");
  const { t } = useTranslation();
  return (
    <>
       <Flex justifyContent="flex-end">
        <StyledForgotPassword
          href="/"
          onClick={e => {
            e.preventDefault();
            setIsOpen(true);
          }}
        >
          {props.label ? props.label : t("RECOVERPASS")}
        </StyledForgotPassword>
      </Flex>
      <StyledDialog
        title={props.title ? props.title : t("RECOVERPASS")}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className={DIALOG_BODY}>
          <p>{t("PASSWORD_RECOVER_ENTER_EMAIL")}</p>
          <Label>
            {t("EMAIL")}
            <InputGroup
              placeholder={t("EMAIL")}
              type="email"
              required
              value={email}
              onChange={e => setMail(e.currentTarget.value)}
            />
          </Label>
        </div>
        <div className={DIALOG_FOOTER}>
            <Flex justifyContent="space-between">
              <StyledButton onClick={() => setIsOpen(false)}>{t("CANCEL")}</StyledButton>
              <StyledTopBarButton
                disabled={email === "" || !isValidEmail(email)}
                onClick={() => {
                  props.dispatch(forgotPassword(email));
                  setMail("");
                  setIsOpen(false);
                }}
              >
                {t("SEND")}
              </StyledTopBarButton>
            </Flex>
        </div>
      </StyledDialog>
    </>
  );
};

export default connect()(ForgotPasswordDialog);
