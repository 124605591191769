import React from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { CurrentCart } from "../services/productCatalogue";
import { reloadAvailability, resetCart } from "../store/actions/cart";
import { ReduxRootType } from "../store/store";
import CartItemTable from "./Cart/CartItemTable";
import CartTopbar from "./Cart/CartTopbar";
import OrderInfoBar from "./Cart/OrderInfoBar";
import CornerBar, { StyledButton } from "./Cart/CornerBar";
import QuickInsert from "./Cart/QuickInsert";
import { Flex } from "./Flex/Flex";
import Confirm from "./Confirm";
import { CheckAvailabilityicon, trashIcon } from "../assets/icons";
import { StyledNoContentCard } from "../pages/shop/NoSelectionView";

const StyledCart = styled.div`
  /* box-shadow: 0px -4px 33px 0px rgba(0, 0, 0, 0.47); */
  width: 100%;
  height: 100%;

  &.horizontal {
  }
`;

// import cartDeliveryStatusUnknown from '../assets/images/cartDeliveryStatusUnknown.png';
const StyledFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-height: 300px;
  gap: 20px;

  &:nth-child(1) {
    order: 0;
    flex: 1 1 auto;
  }

  &:nth-child(2) {
    flex: 0 1 auto;
  }
`;

const StyledCartWrapper = styled(StyledFlexContainer)`
  position: relative;
  padding: 20px 50px 20px 50px;
  background-color: white;
`;

const QuickBar = styled.div`
  position: relative;
  text-align: center;
`;
const QuickBarButtonContainer = styled.div`
  position: absolute;

  right: 0;
  top: 5px;
`;

const StyledCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0px;
`;

export enum CartMode {
  orders = "orders",
  ordersEdit = "ordersEdit",
  shop = "shop"
}

export interface CartProps {
  mode: CartMode;
  order?: CurrentCart;
  title?: React.ReactElement;
  editItemIndex?: number;
  isMinimized?: boolean;
  isVertical?: boolean;
  dispatch: ThunkDispatch<ReduxRootType, {}, any>;
  // if a cart is historical it is only a log of a previous order
  // historical?: boolean;
  // if historical set and isHistoricalEdit enabled an old order becomes editable
  isHistoricalEdit?: boolean;
  onToggleMinimized?: (boolean) => void;
  isLoading?: boolean;
  availabilityLoading?: boolean;
}

export const Cart: React.FC<CartProps> = props => {
  const { t } = useTranslation();

  return (
    <StyledCart
      className={"cart " + (props.isVertical ? "vertical" : "horizontal")}
    >
      <StyledFlexContainer style={{gap:"0px"}}>
        <CartTopbar
          mode={props.mode}
          onToggleMinimized={state =>
            props.onToggleMinimized && props.onToggleMinimized(state)
          }
          isMinimized={props.isMinimized}
        />
        <StyledCartWrapper>
          {props.mode === CartMode.orders && !props.order ?

          <StyledNoContentCard>
          <Flex flexDirection="column" gap="10px" justifyContent="center" className="text"> 
            <h3>{t("NO_ORDER_SELECTED")}</h3>
            <p>{t("NO_ORDER_SELECTED_MESSAGE")}</p>
          </Flex>
          </StyledNoContentCard>
          :
          <>           <OrderInfoBar
            {...props.order}
            isVertical={props.isVertical}
            mode={props.mode}
          />
          <Flex flexDirection="row" justifyContent="space-between">
            <Flex gap="40px">
              <CornerBar isVertical={props.isVertical} mode={props.mode} />
              {props.mode === CartMode.shop && (
                <QuickInsert />
              )}
            </Flex>
            {props.mode === CartMode.shop && (
              <Flex gap="20px">
                <Confirm
                  trigger={
                    <StyledButton icon={trashIcon} intent="danger">
                      {t("CART_RESET")}
                    </StyledButton>
                  }
                  title={t("CART_RESET") + "?"}
                  msg={t("CART_RESET_CONFIRM")}
                  onCancel={() => { }}
                  confirmIntent="danger"
                  onConfirm={() => props.dispatch(resetCart())}
                  cancelLabel={t("NO")}
                  confirmLabel={t("YES")}
                />
                <StyledButton
                  loading={props.availabilityLoading}
                  icon={CheckAvailabilityicon}
                  onClick={() => props.dispatch(reloadAvailability())}
                >
                  {t("CHECK_AVAILABILITY")}
                </StyledButton>
              </Flex>
            )}

          </Flex>
          <StyledCartContainer>
            {/* Cart items */}
            <CartItemTable {...props} />
          </StyledCartContainer>
          </>
        }
        </StyledCartWrapper>
      </StyledFlexContainer>
    </StyledCart>
  );
};
