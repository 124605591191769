import { Button, Dialog, IconName, Intent, MaybeElement, TextArea } from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER,
  DIALOG_FOOTER_ACTIONS
} from "@blueprintjs/core/lib/esm/common/classes";
import * as i18next from 'i18next';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { localeStringConverter } from "../../i18n";
import { modifyOrderViaEmail } from "../../store/actions/order";
import {
  OrderModificationState,
  setOrderEdit
} from "../../store/reducers/order";
import { ReduxRootType } from "../../store/store";
import { ConnectedComponent, unixTimeToLocalizedStr } from "../../utils";
import { StyledTopBarButton } from "./CartTopbar";
import { EmailIcon } from "../../assets/icons";

const mapStateToProps = (state: ReduxRootType) => ({
  modifications: state.orders.modifications,
  // this should be impossible
  orderId: state.orders.detail ? state.orders.detail.id : "",
  sapOrderId: state.orders.detail ? state.orders.detail.sapOrderId : "",
  hasModifications: !!(
    state.orders.modifications && state.orders.modifications.isDirty
  )
});

interface CartOrderEditEmailDialog
  extends ConnectedComponent<typeof mapStateToProps> {
  label?: string;
  cancelOrder?: boolean;
  intent?: Intent;
  icon?: IconName | MaybeElement;
  disableOnNoChanges?: boolean;
  // buttonClass name
  className?: string;
}

const emailTextForMods = (
  mods: OrderModificationState,
  t: i18next.TFunction,
  cancelOrder: boolean,
  browserLocale: string
) => {
  if (cancelOrder) {
    return t("ORDER_CANCEL");
  }

  let text = t("ORDER_EMAILTEXT") + "\n\n";

  if (
    !mods.qtyChanges &&
    !mods.completeDelivery &&
    !mods.deliveryAddress &&
    !mods.deliveryDate &&
    !mods.deliveryType
  ) {
    return "";
  }

  // sort changes
  const sortedList = mods.qtyChanges
    ? mods.qtyChanges.sort(
      (a, b) =>
        (typeof a.posNo === "string" ? parseInt(a.posNo) : a.posNo) -
        (typeof b.posNo === "string" ? parseInt(b.posNo) : b.posNo)
    )
    : [];

  sortedList.forEach(
    item =>
    (text += `${item.new === 0
      ? t("ORDER_REMOVE_ITEM", { posNo: item.posNo, itemId: item.matId })
      : t("ORDER_CHANGE_COUNT", {
        posNo: item.posNo,
        itemId: item.matId,
        newCount: item.new
      })
      }\n`)
  );

  // add some spacing
  text += "\n";

  // append strings for other changes
  if (mods.completeDelivery !== undefined) {
    text += `${t("COMPLETE_DELIVERY")}: ${t(
      mods.completeDelivery ? "YES" : "NO"
    )}\n`;
  }

  if (mods.deliveryAddress !== undefined) {
    text += `${t("DELIVERY_ADDRESSES")}: ${mods.deliveryAddress.label}\n`;
  }

  if (mods.deliveryDate !== undefined) {
    text += `${t("DELIVERY_DATE_WISH")}: ${unixTimeToLocalizedStr(
      mods.deliveryDate,
      browserLocale
    )}\n`;
  }

  if (mods.deliveryType !== undefined) {
    text += `${t("ORDER_TYPE")}: ${mods.deliveryType.label}\n`;
  }

  return text;
};

const StyledEmailEditField = styled(TextArea)`
  min-height: 400px;
`;
const CartOrderEditEmailDialog: React.FC<CartOrderEditEmailDialog> = props => {
  const { t, i18n } = useTranslation();
  const browserLocale = localeStringConverter(i18n.language);
  const [isOpen, setIsOpen] = useState(false);
  const [emailText, setEmailText] = useState(
    props.modifications
      ? emailTextForMods(
        props.modifications,
        t,
        !!props.cancelOrder,
        browserLocale
      )
      : ""
  );

  useEffect(() => {
    setEmailText(
      props.modifications
        ? emailTextForMods(
          props.modifications,
          t,
          !!props.cancelOrder,
          browserLocale
        )
        : ""
    );
  }, [props.modifications, props.cancelOrder, browserLocale, t]);

  return (
    <>
      <StyledTopBarButton
        className={props.className}
        disabled={!!props.disableOnNoChanges ? !props.hasModifications : false}
        intent={props.intent}
        icon={props.icon ? (props.icon as any) : EmailIcon}
        onClick={() => setIsOpen(true)}
      >
        {props.label ? props.label : t("CREATE_EMAIL")}
      </StyledTopBarButton>
      <Dialog
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        title={t("CREATE_EMAIL")}
      >
        <div className={DIALOG_BODY}>
          <p>{t("ORDER_SUBJECT", { orderId: props.sapOrderId })}</p>
          <StyledEmailEditField
            onChange={e => setEmailText(e.currentTarget.value)}
            fill
            value={emailText}
          />
        </div>
        <div className={DIALOG_FOOTER}>
          <div className={DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setIsOpen(false)}>{t("CANCEL")}</Button>
            <Button
              disabled={!props.sapOrderId}
              onClick={() => {
                setIsOpen(false);
                props.dispatch(setOrderEdit(false));
                props.dispatch(
                  modifyOrderViaEmail(
                    props.orderId,
                    t("ORDER_SUBJECT", { orderId: props.sapOrderId }),
                    emailText
                  )
                );
              }}
              intent={props.cancelOrder ? "danger" : "success"}
            >
              {t("SEND")}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps)(CartOrderEditEmailDialog);
