import {combineReducers} from 'redux';

import bottomCart from './bottomCart';
import cart from './cart';
import errors from './error';
import messages from './messages';
import orders from './order';
import search from './search';
import shop from './shop';
import toasts from './toasts';
import user from './user';
import admin from './admin';

export const rootReducer = () => combineReducers({
  user,
  errors,
  shop,
  cart,
  toasts,
  messages,
  bottomCart,
  search,
  orders,
  admin
});
