import { Card, Icon, Menu } from "@blueprintjs/core";
import { ACTIVE, MENU_ITEM } from "@blueprintjs/core/lib/esm/common/classes";
import { ItemListRenderer, ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SipShopCoreServicesVoAddress } from "../services/productCatalogue";
import { hexToRgba, replaceNewLineWithBr, replaceNewLineWithComma } from "../utils";
import { chevronDown } from "../assets/icons";
import { Flex } from "./Flex/Flex";

interface AddressProps {
  disabled?: boolean;
  items: SipShopCoreServicesVoAddress[];
  selectionId: string | null;
  onSelect: (item: SipShopCoreServicesVoAddress | null) => void;
}

export const AddressSelectComponent = Select.ofType<
  SipShopCoreServicesVoAddress
>();

const AddressSelectWrapper = styled.div`

.bp5-card {
  div{
    min-height:30px;
  }
  &:hover {
  box-shadow: none !important;
}
}
  .bp5-menu-item{
    color: var(--text-primary, #535B67) !important;
    &.bp5-active{
      background-color: ${hexToRgba('#00703D', 0.1)} !important; 
    }
    &:hover{
      background-color: ${hexToRgba('#00703D', 0.1)} !important; 
    }
  }
`

const StyledAddressCard = styled(Card) <{ disabled?: boolean }>`

box-shadow: none !important;

  color: var(--text-primary, #535B67) !important;
  border: 1px solid var(--border, #E4E4E7) !important;
  box-shadow: none !important;
  padding: 8px 12px;
  position: relative;
  font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important; 
  ${props =>
    props.disabled
      ? `
    
    cursor: not-allowed;
    opacity: 0.5;
  `
      : ""}

    >p {
      margin: 0 !important;
      height: 30px;
      display: flex;
      align-items: center;
    }
`;

const AddressSelect: React.FC<AddressProps> = props => {
  const { t } = useTranslation();
  const [
    selectedItem,
    setSelected
  ] = useState<SipShopCoreServicesVoAddress | null>(null);

  useEffect(() => {
    if (!props.selectionId) {
      setSelected(null);
      return;
    }
    const selection = props.items.find(item => item.id === props.selectionId);
    setSelected(selection ? selection : null);
  }, [props.selectionId, props.items]);
  const isDisabled = props.disabled || !props.items || props.items.length === 0;
  return (
    <AddressSelectWrapper>
      <AddressSelectComponent
        filterable={false}
        disabled={isDisabled}
        inputProps={{ placeholder: t("DELIVERY_ADDRESSES") }}
        activeItem={selectedItem}
        items={props.items ? props.items : []}
        itemRenderer={addressSuggestItemRenderer}
        itemListRenderer={itemListRenderer}
        onItemSelect={props.onSelect}
        popoverProps={{ minimal: true, matchTargetWidth: true, usePortal:false }}
      >
        <StyledAddressCard disabled={isDisabled} interactive={!props.disabled}>
          <Flex alignItems="center" justifyContent="space-between">
          {selectedItem ? (
            <Address address={selectedItem} />
          ) : (
            <p>{t("NO_ADDRESS")}</p>
          )}
          {chevronDown}
          </Flex>
        </StyledAddressCard>
      </AddressSelectComponent>
    </AddressSelectWrapper>
  );
};

const Address: React.FC<{ address: SipShopCoreServicesVoAddress }> = ({
  address
}) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: replaceNewLineWithComma(address.addressText)
      }}
    />
  );
};

const itemListRenderer: ItemListRenderer<
  SipShopCoreServicesVoAddress
> = props => (
  <Menu ulRef={props.itemsParentRef}>
    {props.items.map((item, index) => props.renderItem(item, index))}
  </Menu>
);

const addressSuggestItemRenderer: ItemRenderer<SipShopCoreServicesVoAddress> = (
  item: SipShopCoreServicesVoAddress,
  itemProps
) => (
  <div
    className={MENU_ITEM + (itemProps.modifiers.active ? " " + ACTIVE : "")}
    onClick={itemProps.handleClick}
    key={item.id}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: replaceNewLineWithBr(item.addressText)
      }}
    />
  </div>
);

export default AddressSelect;
