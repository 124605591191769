import { ToastProps, Spinner, OverlayToaster } from "@blueprintjs/core";
import React, { lazy, Suspense, useCallback, useEffect } from "react";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate, Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";
import "./assets/scss/App.scss";
import { DROPPABLE_CART_ID } from "./components/Cart/CartItemTable";
import MessagesDialog from "./components/MessagesDialog";
import Header from "./Header";
import { initServices } from "./services/api";
import {
  dragToCart,
  ProductTableDraggableId,
  reorderCart
} from "./store/actions/cart";
import { clearToasts, ToastType } from "./store/reducers/toasts";
import { ReduxRootType } from "./store/store";

const ShopPage = lazy(() => import("./pages/shop/Shop"));
const AdminPage = lazy(() => import("./pages/admin/Admin"));
const OrdersPage = lazy(() => import("./pages/orders/Orders"));
const HomePage = lazy(() => import("./pages/home/Home"));

// @ts-ignore
const FullScreenSpinner = styled(Spinner)`
  height: 100%;
`;

export const App: React.FC<{
  isLoggedIn: boolean;
  locale?: string;
  toasts: ToastType[];
  dispatch: ThunkDispatch<{}, {}, any>;
  isAdmin: boolean;
}> = ({ isLoggedIn, locale, toasts, dispatch, isAdmin }) => {
  const toaster = React.createRef<OverlayToaster>();
  const { t, i18n } = useTranslation();

  // this will store services on the window object
  useEffect(() => {
    initServices();
    // sync language with store
    if (locale && i18n.language !== locale) {
      i18n.changeLanguage(locale);
    }
    // eslint-disable-next-line
  }, []);

  const onDragEndCallback = useCallback(onDragEndHandlerCreator(dispatch), [
    dispatch
  ]);

  useEffect(() => {
    if (toasts.length === 0) {
      return;
    }
    toasts.forEach(toast => {
      if (!toaster.current) {
        console.warn("global toaster not available");
        return;
      }

      const toastProps: ToastProps = {
        icon: toast.icon,
        intent: toast.intent,
        message: toast.localize ? t(toast.message) : toast.message,
        action: toast.actionIcon && {
          icon: toast.actionIcon,
          onClick: () =>
            dispatch({ type: toast.actionAction, payload: toast.actionPayload })
        }
      };

      toaster.current.show(toastProps);
    });

    // clear all toasts from state
    dispatch(clearToasts());
  }, [toaster, dispatch, t, toasts]);

  // if user is logged in try doing an api request and see if that fails
  // @ts-ignore
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="page">
          <Suspense fallback={<FullScreenSpinner intent="success" />}>
            {isLoggedIn ? (
              <>
                {/* drag drop must be top level to support drag drop between cart
              and product */}
                <DragDropContext onDragEnd={onDragEndCallback}>
                  <Routes>
                    <Route path="/orders/*" element={<OrdersPage />} />
                    {isAdmin && <Route path="/admin/*" element={<AdminPage />} />}
                    <Route path="/*" element={<ShopPage />} />
                  </Routes>
                </DragDropContext>
              </>
            ) : (
              <>
                <Navigate to="/" />
                <Routes>
                  <Route path="/*" element={<HomePage />} />
                </Routes>
              </>
            )}
          </Suspense>
          <OverlayToaster
            ref={toaster}
            className="global-toaster"
            position="top-right"
          />
          {/* Display global services dialogs */}
          <MessagesDialog />
        </div>
      </div>
    </Router>
  );
};

/**
 * handle global drag drop actions
 * @param dispatch
 */
const onDragEndHandlerCreator = (
  dispatch: ThunkDispatch<{}, {}, any>
): OnDragEndResponder => (result, provider) => {
  if (!result.destination) {
    return;
  }

  // dropping a product into the cart
  if (
    result.source.droppableId !== DROPPABLE_CART_ID &&
    result.destination.droppableId === DROPPABLE_CART_ID
  ) {
    dispatch(
      dragToCart(
        result.source.droppableId as ProductTableDraggableId,
        result.source.index,
        result.destination.index,
        result.draggableId
      )
    );
    return;
  }
  if (
    result.source.droppableId === DROPPABLE_CART_ID &&
    result.destination.droppableId === result.source.droppableId
  ) {
    // on case we drop from cart to cart
    // dispatch reorder action
    dispatch(reorderCart(result.source.index, result.destination!.index));
    return;
  }
};

const mapStateToProps = (state: ReduxRootType) => ({
  isLoggedIn: state.user !== null && state.user.id !== "",
  isAdmin: state.user !== null && state.user.isAdmin,
  locale: state.user !== null ? state.user.locale : undefined,
  toasts: state.toasts
});

export default connect(mapStateToProps)(App);
