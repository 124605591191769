import {
  Button,
  Classes,
  Menu,
  Popover,
  PopoverPosition,
} from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { ReduxRootType } from "../store/store";
import { chevronDown, helpIcon } from "../assets/icons";
import { Flex } from "./Flex/Flex";
import styled from "styled-components";
import { LanguageCode } from "../store/actions/user";

const StyledHelpMenu = styled(Menu)`
  min-width: auto;
`

const StyledManuButton = styled(Button)`
  &.bp5-active {
    background-color: var(--bg-secondary, #F4F4F5) !important;
    transition: all ease-in 0.5;
    color: var(--bg-primary, "#00703D") !important;
  }
  &.bp5-active .bp5-button-text {
    color: var(--bg-primary, "#00703D");
  }

  &.bp5-active .chevronIcon svg{
    transform: rotate(180deg);
  }
`

// opens manual in new tab
const openUserManual = (language: LanguageCode) => {
  switch (language) {
    case LanguageCode.DE:
    case LanguageCode.PL:
    case LanguageCode.EN:
      window.open(`/guides/SIPLeitfaden_${language}.pdf`);
      return;
    default:
      window.open(`/guides/SIPLeitfaden_${LanguageCode.EN}.pdf`);
      return;
  }
};

// opens color booklet in new tab
const openColorBooklet = (isShopES: boolean, language: LanguageCode) => {
  if (isShopES) {
    if (language === LanguageCode.PT) {
      window.open(`/online-shop/decors_colours_pt.pdf`);
    } else {
      window.open(`/online-shop/decors_colours_es.pdf`);
    }
  } else {
    window.open(`/online-shop/decors_colours.pdf`);
  }
  return;
};

interface HelpMenuProps {
  isShopES: boolean;
}

const HelpMenu: React.FC<HelpMenuProps> = props => {
  const { t, i18n } = useTranslation();
  return (
    <Popover
      position={PopoverPosition.BOTTOM_LEFT}
      minimal
      content={
        <StyledHelpMenu>
          <Flex flexDirection="column">
            <Button
              onClick={() => openUserManual(i18n.language as LanguageCode)}
              className={Classes.MINIMAL + " desktop-help"}
              text={t("USER_MANUAL")}
              alignText="left"
            />
            <Button
              onClick={() => openColorBooklet(props.isShopES, i18n.language as LanguageCode)}
              className={Classes.MINIMAL + " desktop-help"}
              text={t("COLOR_BOOKLET")}
              alignText="left"
            />
          </Flex>
        </StyledHelpMenu>
      }
    >
      <StyledManuButton
        className={Classes.MINIMAL}
        text={t("HELP")}
        icon={helpIcon}
        rightIcon={<div className="chevronIcon">{chevronDown}</div>}
      />
    </Popover>
  );
};

const mapStateToProps = (state: ReduxRootType) => ({
  userEmail: state.user !== null ? state.user.email : "",
  companyName: state.user !== null ? state.user.companyName : ""
});

export default connect(mapStateToProps)(HelpMenu);
