import {actionCreator} from '../store';


export const SET_CART_MINIMIZED = 'SET_CART_MINIMIZED';
export const SET_CART_SIZE = 'SET_CART_SIZE';
export const CART_OPEN = 'CART_OPEN';

export interface BottomCartState {
  minimized: boolean;
  size: number|undefined;
  // size before cart was minimized
  backupSize: number|undefined;
}


export const setCartMinimizedAction = (minimized: boolean) =>
    actionCreator(SET_CART_MINIMIZED, minimized);

export const setCartSizeAction = (size: number|undefined) =>
    actionCreator(SET_CART_SIZE, size);

export const cartOpenAction = () => actionCreator(CART_OPEN, undefined);

type BottomBarActionType =
    ReturnType<typeof setCartMinimizedAction|typeof setCartSizeAction|
               typeof cartOpenAction>;


// size of the collapsed order panel
const minimizedSize = 85;
const minimizeSnapSize = 350;

export const initialState = {
  minimized: true,
  size: minimizedSize,
  backupSize: Math.min(window.innerHeight * 0.5, 500)
}

const bottomBarReducer =
    (state: BottomCartState = initialState, action: BottomBarActionType):
        BottomCartState => {
          switch (action.type) {
            case CART_OPEN:
              if (!state.minimized) {
                return state
              }
              return {
                ...state,
                minimized: false,
                size: state.minimized ?
                    Math.max(minimizeSnapSize, state.backupSize as number) :
                    Math.max(minimizeSnapSize, state.size as number)

              };
            case SET_CART_MINIMIZED:
              return {
                ...state,
                // restore old size of expanded before
                // if no backupSize restore to 70% of window size or 1000px
                size: action.payload ?
                    minimizedSize :
                    state.backupSize ? state.backupSize :
                                       Math.min(window.innerHeight * 0.7, 1000),
                minimized: action.payload,
                backupSize: action.payload ? state.size : state.backupSize
              };
            case SET_CART_SIZE:
              const adjustedSize = (typeof action.payload === 'number' &&
                                    action.payload <= minimizeSnapSize) ?
                  minimizedSize :
                  action.payload;
              // console.log(
              //     'adjustedSize', adjustedSize, minimizedSize,
              //     action.payload, (action.payload as any) <=
              //     minimizeSnapSize)
              return {
                ...state,
                size: adjustedSize,
                minimized: adjustedSize === minimizedSize

              };
            default:
              return state
          }
        };

export default bottomBarReducer;