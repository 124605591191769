import {
    SipShopCoreServicesVoImportHistory,
    SipShopCoreServicesVoCompanyTable,
    SipShopCoreServicesVoCompanyUserTable,
    SipShopCoreServicesVoCompanyAddressTable,
} from "../../services/admin";
import { actionCreator } from "../store";

export const SET_IMPORT_HISTORY = "SET_IMPORT_HISTORY";
export const SET_COMPANY_USERS = "SET_COMPANY_USERS";
export const SET_COMPANIES = "SET_COMPANIES"
export const SET_COMPANY_ADDRESSES = "SET_COMPANY_ADDRESSES";
export const SET_COMPANY_ADDRESSES_PAGE_SPEC = "SET_COMPANY_ADDRESSES_PAGE_SPEC";
export const SET_COMPANY_USERS_PAGE_SPEC = "SET_COMPANY_USERS_PAGE_SPEC"
export const SET_COMPANY_PAGE_SPEC = "SET_COMPANY_PAGE_SPEC"
export const TOGGLE_USE_ES = "TOGGLE_USE_ES"
export const SET_IMPORT_IN_PROGRESS = "SET_IMPORT_IN_PROGRESS"
export const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE"

/**
 * State type
 */
export interface AdminState {
    importHistoryPageSpec: PageSpec | null;
    // null means loading state
    importHistory: SipShopCoreServicesVoImportHistory | null;
    // null means loading state
    importHistoryESPageSpec: PageSpec | null;
    importHistoryES: SipShopCoreServicesVoImportHistory | null;
    usersPageSpec: FilteredSortedPageSpec| null;
    users: SipShopCoreServicesVoCompanyUserTable | null;
    usersPageESSpec: FilteredSortedPageSpec| null;
    usersES: SipShopCoreServicesVoCompanyUserTable | null;
    addressesPageSpec: FilteredSortedPageSpec| null;
    addresses: SipShopCoreServicesVoCompanyAddressTable | null;
    addressesPageESSpec: FilteredSortedPageSpec| null;
    addressesES: SipShopCoreServicesVoCompanyAddressTable | null;
    companiesPageSpec: SortedPageSpec| null;
    companies: SipShopCoreServicesVoCompanyTable | null;
    companiesPageESSpec: SortedPageSpec| null;
    companiesES: SipShopCoreServicesVoCompanyTable | null;
    useEs: boolean;
    importInProgress: boolean;
}

export interface PageSpec {
    useES: boolean;
    pageSize: number;
    page: number;
}

export interface FilteredSortedPageSpec {
    useES: boolean;
    pageSize: number;
    page: number;
    searchTerm: string;
    filter: FilterEnabled;
    sortField: SortAddresses | SortCompanies | SortUsers;
    sortAsc: boolean;
}

export interface SortedPageSpec {
    useES: boolean;
    pageSize: number;
    page: number;
    searchTerm: string;
    sortField: SortAddresses | SortCompanies | SortUsers;
    sortAsc: boolean;
}

export enum CompanyUserSearchMode {
    COMPANY_ID = "companyId",
    USER_ID_OR_NAME= "userIdOrName"
}

export enum CompanySearchMode {
    COMPANY_ID = "companyId",
    COMPANY_NAME = "companyName"
}

export enum CompanyAddressSearchMode {
    COMPANY_ID = "companyId",
    ADDRESS_ID_OR_NAME = "addressIdOrName"
}

export enum FilterEnabled {
    FILTER_ENABLED_ALL = 'all',
    FILTER_ENABLED_ENABLED = 'enabled',
    FILTER_ENABLED_DISABLED = 'disabled'
}

export enum SortAddresses {
    SORT_ADDRESSES_COMPANY = 'companyName',
    SORT_ADDRESSES_SAP_ID = 'sapId',
    SORT_ADDRESSES_TEXT = 'addressText',
    SORT_ADDRESSES_ENABLED = 'enabled'
}

export enum SortCompanies {
    SORT_COMPANIES_NAME = 'name',
    SORT_COMPANIES_SAP_ID = 'sapId',
    SORT_COMPANIES_DELIVERY_DAY = 'deliveryDay',
    SORT_COMPANIES_BRAND_ID = 'brand',
    SORT_COMPANIES_COUNTRY = 'country',
    SORT_COMPANIES_CONSI = 'consi',
    SORT_COMPANIES_SIP_CONTACT = 'sipContact'
}


export enum SortUsers {
    SORT_USERS_COMPANY = 'companyName',
    SORT_USERS_LASTNAME = 'lastName',
    SORT_USERS_FIRSTNAME = 'firstName',
    SORT_USERS_EMAIL = 'email',
    SORT_USERS_ENABLED = 'enabled'
}

/**
 * Action creators
 */

export const setImportHistoryAction = (specHist: {spec: PageSpec, history: SipShopCoreServicesVoImportHistory} | null) =>
  actionCreator(SET_IMPORT_HISTORY, specHist);

export const setCompanyUsersAction = (specUsers: {users: SipShopCoreServicesVoCompanyUserTable} | null) =>
  actionCreator(SET_COMPANY_USERS, specUsers);

export const setCompaniesAction = (specCompanies: {companies: SipShopCoreServicesVoCompanyTable} | null) =>
    actionCreator(SET_COMPANIES, specCompanies);

export const setCompanyAddressesAction = (specAddresses: {addresses: SipShopCoreServicesVoCompanyAddressTable} | null) =>
  actionCreator(SET_COMPANY_ADDRESSES, specAddresses);

export const setCompanyAddressesPageSpecAction = (specAddresses: {spec: FilteredSortedPageSpec, useEs: boolean} | null) =>
    actionCreator(SET_COMPANY_ADDRESSES_PAGE_SPEC, specAddresses);

export const setCompanyUsersPageSpecAction = (specUsers: {spec: FilteredSortedPageSpec, useEs: boolean} | null) =>
    actionCreator(SET_COMPANY_USERS_PAGE_SPEC, specUsers);

export const setCompanyPageSpecAction = (specCompanies: {spec: FilteredSortedPageSpec, useEs: boolean} | null) =>
    actionCreator(SET_COMPANY_PAGE_SPEC, specCompanies);

export const toggleUseEs = () =>
    actionCreator(TOGGLE_USE_ES, {});

export const setImportInProgressAction = (inProgress: boolean) => actionCreator(SET_IMPORT_IN_PROGRESS, inProgress);

export const resetAdminStateToInitial = () => actionCreator(RESET_TO_INITIAL_STATE, initialState);

/**
 * Initial state
 */
const initialState = {
    importHistoryPageSpec: null,
    importHistory: null,
    importHistoryESPageSpec: null,
    importHistoryES: null,
    usersPageSpec: null,
    users: null,
    usersPageESSpec: null,
    usersES: null,
    addressesPageSpec: null,
    addresses: null,
    addressesPageESSpec: null,
    addressesES: null,
    companiesPageSpec: null,
    companies: null,
    companiesPageESSpec: null,
    companiesES: null,
    useEs: false,
    importInProgress: false,
};

type AdminActionType = ReturnType<
  | typeof setImportHistoryAction
  | typeof setCompanyUsersAction
  | typeof setCompanyAddressesAction
  | typeof setCompaniesAction
  | typeof setCompanyAddressesPageSpecAction
  | typeof setCompanyUsersPageSpecAction
  | typeof setCompanyPageSpecAction
  | typeof toggleUseEs
  | typeof setImportInProgressAction
  | typeof resetAdminStateToInitial
>;

const adminReducer = (
  state: AdminState = initialState,
  action: AdminActionType
): AdminState => {
  switch (action.type) {
    case SET_IMPORT_HISTORY:
        if (action.payload && action.payload.history.database === 'ES') {
            return {
                ...state,
                importHistoryESPageSpec: action.payload.spec,
                importHistoryES: action.payload.history
            };
        }
        return {
            ...state,
            importHistoryPageSpec: action.payload ? action.payload.spec : action.payload,
            importHistory: action.payload ? action.payload.history : action.payload
        };
    case SET_COMPANY_ADDRESSES:
        if (action.payload && action.payload.addresses.database === 'ES') {
            return {
                ...state,
                addressesES: action.payload.addresses
            };
        }
        return {
            ...state,
            addresses: action.payload ? action.payload.addresses : action.payload
        };
    case SET_COMPANY_ADDRESSES_PAGE_SPEC:
            if (action.payload && action.payload.useEs) {
                return {
                    ...state,
                    addressesPageESSpec: action.payload.spec,
                };
            }
            return {
                ...state,
                addressesPageSpec: action.payload ? action.payload.spec : action.payload,
            };
    case SET_COMPANY_USERS_PAGE_SPEC:
        if (action.payload && action.payload.useEs) {
            return {
                ...state,
                usersPageESSpec: action.payload.spec,
            };
        }
        return {
            ...state,
            usersPageSpec: action.payload ? action.payload.spec : action.payload,
        };
    case SET_COMPANY_PAGE_SPEC:
        if (action.payload && action.payload.useEs) {
            return {
                ...state,
                companiesPageESSpec: action.payload.spec,
            };
        }
        return {
            ...state,
            companiesPageSpec: action.payload ? action.payload.spec : action.payload,
        };
    case SET_COMPANIES:
        if (action.payload && action.payload.companies.database === 'ES') {
            return {
                ...state,
                companiesES: action.payload.companies
            };
        }
        return {
            ...state,
            companies: action.payload ? action.payload.companies : action.payload
        };
    case SET_COMPANY_USERS:
        if (action.payload && action.payload.users.database === 'ES') {
            return {
                ...state,
                usersES: action.payload.users
            };
        }
        return {
            ...state,
            users: action.payload ? action.payload.users : action.payload
        };
    case TOGGLE_USE_ES:
        return {
            ...state,
            useEs: !state.useEs
        };
    case SET_IMPORT_IN_PROGRESS:
        return {
            ...state,
            importInProgress: action.payload
        };
    case RESET_TO_INITIAL_STATE:
        return {
            ...initialState
        }
  }
  return state;
};

export default adminReducer;
