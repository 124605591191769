import {
  Button,
  Classes,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverPosition,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import styled from "styled-components";

import { ReduxRootType } from "../store/store";
import { logout } from "../store/actions/user";
import ResetPassword from "./ResetPassword";
import { chevronDown, logOutIcon, passwordIcon, userIcon } from "../assets/icons";
import { Flex } from "./Flex/Flex";
import { StyledDialog } from "../pages/home/LoginForm";

const StyledUserMenuWrapper = styled(Flex)`
  padding: 10px;
  color: var(--text-primary, "#535B67");
  >h4 {
    margin: 0;
  }
`

const StyledUserButton = styled(Button)`
  .bp5-button-text {
    max-width: 180px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.bp5-active {
    background: var(--bg-secondary, #F4F4F5) !important;
    transition: all ease-in 0.5;
    color: var(--bg-primary, "#00703D") !important;
  }
  &.bp5-active .bp5-button-text {
    color: var(--bg-primary, "#00703D");
  }

  &.bp5-active .chevronIcon svg{
    transform: rotate(180deg);
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: var(--text-primary, "#535B67");
  svg {
    color: var(--text-primary, "#535B67");
  }
`

interface UserMenuDropdownProps extends UserMenuProps {
  onResetPassword: () => void;
}

const UserMenuDropdown: React.FC<UserMenuDropdownProps> = props => {
  const { t } = useTranslation();
  return (
    <>
      <Menu>
        <StyledUserMenuWrapper flexDirection="column">
          <h4>{props.companyName}</h4>
          <span>{props.userEmail}</span>
        </StyledUserMenuWrapper>
        <MenuDivider />
        <StyledMenuItem
          onClick={props.onResetPassword}
          icon={passwordIcon}
          text={t("PASSWORD_CHANGE")}
        />
        <StyledMenuItem
          icon={logOutIcon}
          onClick={() => props.dispatch(logout())}
          text={t("DISCONNECT")}
        />
      </Menu>
    </>
  );
};

interface UserMenuProps {
  companyName: string;
  userEmail: string;
  dispatch: ThunkDispatch<{}, {}, any>;
}

const UserMenu: React.FC<UserMenuProps> = props => {
  const { t } = useTranslation();
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false);
  return (
    <>
      <Popover
        minimal
        position={PopoverPosition.BOTTOM_LEFT}
        matchTargetWidth
        content={
          <UserMenuDropdown
            {...props}
            onResetPassword={() => setResetPasswordOpen(true)}
          />
        }
      >
        <StyledUserButton
          icon={userIcon}
          rightIcon={<div className="chevronIcon">{chevronDown}</div>}
          className={Classes.MINIMAL}
          text={props.companyName}
        />
      </Popover>
      <StyledDialog
        icon={passwordIcon}
        title={t("PASSWORD_CHANGE")}
        isOpen={isResetPasswordOpen}
        onClose={() => setResetPasswordOpen(false)}
      >
        <ResetPassword onCancel={() => setResetPasswordOpen(false)} />
      </StyledDialog>
    </>
  );
};

const mapStateToProps = (state: ReduxRootType) => ({
  userEmail: state.user !== null ? state.user.email : "",
  companyName: state.user !== null ? state.user.companyName : ""
});

export default connect(mapStateToProps)(UserMenu);
