import { Button, Classes, Dialog } from "@blueprintjs/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { localeStringConverter } from "../../i18n";
import { ReduxRootType } from "../../store/store";
import {
  ConnectedComponent,
  downloadFileFromURL,
  unixTimeToLocalizedStr
} from "../../utils";
import FlexBoxTable, { FlexBoxColumn } from "../FlexTable/FlexTable";
import { StyledButton } from "./CornerBar";
import { DocumentsIcon } from "../../assets/icons";

const mapStateToProps = (state: ReduxRootType) => ({
  orderId: state.orders.detail && state.orders.detail.id,
  documents: state.orders.detail && state.orders.detail.documents
});

interface DocumentsDialogProps
  extends ConnectedComponent<typeof mapStateToProps> { }

const DocumentsDialog: React.FC<DocumentsDialogProps> = props => {
  const { t, i18n } = useTranslation();
  const browserLocaleStr = localeStringConverter(i18n.language);
  const [isDocDialogOpen, setDocDialogOpen] = useState(false);
  return (
    <>
      <StyledButton
        disabled={
          !props.orderId || !props.documents || props.documents.length === 0
        }
        onClick={() => setDocDialogOpen(true)}
        icon={DocumentsIcon}
      >
        {t("DOCUMENTS")}
      </StyledButton>
      <Dialog
        style={{ width: "70vw", maxWidth: 800 }}
        onClose={() => setDocDialogOpen(false)}
        title={t("DOCUMENTS")}
        isCloseButtonShown={true}
        canOutsideClickClose
        canEscapeKeyClose
        isOpen={isDocDialogOpen}
      >
        <div className={Classes.DIALOG_BODY}>
          <FlexBoxTable striped data={props.documents ? props.documents : []}>
            <FlexBoxColumn dataKey="name" name={t("NAME")} flex="1 0 140px" />
            <FlexBoxColumn
              dataKey="addedAt"
              name={t("ADDED_AT")}
              flex="0.2 0 120px"
            >
              {cellProps =>
                unixTimeToLocalizedStr(cellProps.data, browserLocaleStr)
              }
            </FlexBoxColumn>
            <FlexBoxColumn name={t("DOWNLOAD")} flex="0 1 120px">
              {cellProps => (
                <Button
                  fill
                  onClick={() =>
                    downloadFileFromURL(
                      cellProps.rowData.tmpName,
                      cellProps.rowData.fileName
                    )
                  }
                >
                  {t("DOWNLOAD")}
                </Button>
              )}
            </FlexBoxColumn>
          </FlexBoxTable>
        </div>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps)(DocumentsDialog);
