import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { asyncActionWrapper, getCatalogueService } from "../../services/api";
import {
  SipShopCoreServicesVoLabeledData,
  SipShopCoreServicesVoProduct,
  SipShopCoreServicesVoProductGroup,
  SipShopCoreServicesVoTreeEntry
} from "../../services/productCatalogue";
import { ErrorType } from "../reducers/error";
import {
  CustomDecorState,
  setCustomDecorAction,
  setProductTreeAction,
  setSelectedSeriesAction,
  setSelectionAction,
  setSelectionPathAction,
  setSeriesAction
} from "../reducers/shop";
import { ReduxRootType } from "../store";
import { setProductSeriesTree } from "./search";
import { Location, NavigateFunction, useLocation } from "react-router-dom";

export enum MethodNames {
  getProductById = "getProductById",
  getProductGroupById = "getProductGroupById"
}

/**
 *
 */
export const reloadSelection = () => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const state = getState();
  if (!state.shop.selection) {
    return;
  }

  if (state.shop.selection.item) {
    dispatch(selectProduct(state.shop.selection.item.id));
  } else {
    dispatch(selectProductGroup(state.shop.selection.group.id));
  }
};

/**
 *
 * @param node
 * @param path
 */
export const selectNode = (
  node: SipShopCoreServicesVoTreeEntry,
  path: number[] | null,
  navigate: NavigateFunction
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  // select path node
  dispatch(setSelectionPathAction(path));
  if (node && node.methodName) {
   
    switch (node.methodName) {
      case MethodNames.getProductById:
        navigate("/product/" + node.id);
        break;
      case MethodNames.getProductGroupById:
        navigate("/product-group/" + node.id);
        break;
    }
  }
};

/**
 *
 * @param id
 * @param path
 */
export const selectProduct = (id: string) => async (
  dispatch: Dispatch,
  getState: () => ReduxRootType
) => {
  // reset selection
  // dispatch(setSelectionAction(null));
  try {
    const call = getCatalogueService().operations.getProductById(id);
    const product = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.getProduct
    )) as SipShopCoreServicesVoProduct;

    const groupCall = getCatalogueService().operations.getProductGroupById(
      product.productGroup.data
    );
    const group = (await asyncActionWrapper(
      groupCall,
      dispatch,
      ErrorType.getProductGroup
    )) as SipShopCoreServicesVoProductGroup;

    dispatch(setSelectionAction({ item: product, group: group }));
  } catch (e) {
    dispatch(setSelectionAction(undefined));
  }
};

export const selectProductFromCart = (matId: string, navigate: NavigateFunction, location: Location) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  // reset selection
  dispatch(setSelectionPathAction(null));
  try {
    const call = getCatalogueService().operations.searchProductsByMatId(matId);
    const products = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.getProduct
    )) as SipShopCoreServicesVoProduct[];
    if (products && products.length > 0 && products[0]) {
      const productURL = "/product/" + products[0].id;
      if (location.pathname !== productURL) {
        navigate(productURL);
      }
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * selectProductGroup selects a product
 * @param id if the product group
 */
export const selectProductGroup = (id: string) => async (
  dispatch: Dispatch,
  getState: () => ReduxRootType
) => {
  // reset selection
  dispatch(setSelectionAction(null));
  try {
    const call = getCatalogueService().operations.getProductGroupById(id);
    const group = await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.getProductGroup
    );
    dispatch(
      setSelectionAction({
        group: group as SipShopCoreServicesVoProductGroup,
        item: undefined
      })
    );
  } catch (e) {
    console.error(e);
    dispatch(setSelectionAction(undefined));
  }
};

/**
 * Async Redux thunk actions
 */
export const reloadSeriesList = () => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  dispatch(setSeriesAction(null));

  // prepare the call it will be only executed in the wrapper
  const call = getCatalogueService().operations.getSeries();
  try {
    let data = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.getSeriesFailed
    )) as SipShopCoreServicesVoLabeledData[];

    // set the selected series item
    if (data && data.length > 1) {
      dispatch(setSelectedSeriesAction(data[1]));
      dispatch(setProductSeriesTree());
    }

    // filter the data
    // data = data.filter(item => item.data !== '');
    // store user session
    dispatch(setSeriesAction(data));
  } catch (e) {
    // reset user since something went wrong
    dispatch(setSeriesAction([]));
  }
};

/**
 *
 * @param id
 */
export const reloadSeriesTree = (id: string) => async (
  dispatch: Dispatch,
  getState: () => ReduxRootType
) => {
  dispatch(setSeriesAction(null));

  const call = getCatalogueService().operations.getProductGroupById(id);

  try {
    const data = await asyncActionWrapper(
      call as any,
      dispatch,
      ErrorType.getSeriesFailed
    );
    // store user session
    dispatch(setProductTreeAction(data as any[]));
  } catch (e) {
    // reset user since something went wrong
    dispatch(setSeriesAction([]));
  }
};

/**
 *
 * @param matId
 */
export const getCustomDecor = (matId: string) => async (
  dispatch: Dispatch,
  getState: () => ReduxRootType
) => {
  dispatch(setCustomDecorAction(null));
  const call = getCatalogueService().operations.getCustomDecorsByMatId(matId);
  const decor = await asyncActionWrapper(
    call as any,
    dispatch,
    ErrorType.getSeriesFailed
  );
  dispatch(setCustomDecorAction(decor as CustomDecorState));
};
