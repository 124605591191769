import {
  Callout,
  Dialog,
  FormGroup,
  InputGroup,
} from "@blueprintjs/core";
import {
  DIALOG_BODY,
  DIALOG_FOOTER,
  DIALOG_FOOTER_ACTIONS
} from "@blueprintjs/core/lib/esm/common/classes";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import ForgotPasswordDialog from "../../components/ForgotPasswordDialog";
import { login } from "../../store/actions/user";
import { ErrorType } from "../../store/reducers/error";
import { UserState } from "../../store/reducers/user";
import { ReduxRootType } from "../../store/store";
import { ConnectedComponent } from "../../utils";
import { StyledTopBarButton } from "../../components/Cart/CartTopbar";
import { Flex } from "../../components/Flex/Flex";
import { arrowIcon } from "../../assets/icons";
import { StyledButton } from "../../components/Cart/CornerBar";

export const StyledDialog = styled(Dialog)`
  background: white;
  border-radius: 0;
  padding: 20px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--text-primary, #535B67) !important;

  .bp5-dialog-header{
    padding: 0;
    box-shadow: none;
    gap: 10px;
    .bp5-heading{
      color: var(--text-primary, #535B67) !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.5px;
    }
  }

  .bp5-dialog-body{
    padding: 0;
    margin: 0;
    overflow: scroll;
    .bp5-input{
    padding: 8px 12px; 
    border: 1px solid var(--border, #E4E4E7);
    box-shadow: none !important;
    color: var(--text-primary, #535B67) !important;
    height: 40px;
  }
  }

  .bp5-dialog-footer{
    padding: 0;
    margin: 0;
    .bp5-dialog-footer-actions{
      justify-content: flex-start;
      button {
        margin: 0;
      }
    }
  }
`

const StyledLogin = styled.div`
  min-width: 350px;
  .bp5-form-group {
    margin-bottom: 8px !important;
  }

  .bp5-label{
    text-align: left;
    color: var(--text-primary, #535B67) !important;
  }

  .bp5-input{
    padding: 8px 12px; 
    border: 1px solid var(--border, #E4E4E7) !important;
    box-shadow: none !important;
    color: var(--text-primary, #535B67) !important;
  }
`;

const StyledButtonWrapper = styled.div`
  button {
    width: 100%;
  }
`

const StyledSelectMenuItem = styled.div`
    padding: 12px;
    display: flex;
    border: 1px solid var(--border, #E4E4E7);
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
`;

const mapStateToProps = (state: ReduxRootType) => ({
  errors: state.errors,
  initialEmail: state.user ? state.user.email : ""
});

interface LoginFromProps extends ConnectedComponent<typeof mapStateToProps> {}

const LoginForm: React.FC<LoginFromProps> = props => {
  const { t } = useTranslation();
  const [pass, setPass] = React.useState("");
  const [mail, setMail] = React.useState(props.initialEmail);
  // TODO: move some of this state into redux
  const [requiresCompanySelect, setCompanySelect] = React.useState(false);
  const [user, setUserState] = React.useState<UserState | undefined>(undefined);
  const dispatch = props.dispatch;

  const onReset = React.useCallback(() => {
    setCompanySelect(false);
    setUserState(undefined);
    setPass("");
  }, [setCompanySelect, setUserState, setPass]);

  const onLogin = React.useCallback(
    (
      e:
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      e.preventDefault();
      ((dispatch(login(mail, pass)) as unknown) as Promise<{
        requireCompanySelect?: boolean;
        user?: UserState;
      }>).then(test => {
        if (test && test.requireCompanySelect) {
          setUserState(test.user);
          setCompanySelect(true);
        }
      });
    },
    [dispatch, mail, pass]
  );
  return (
    <StyledLogin>
      <form onSubmit={onLogin}>
        {props.errors[ErrorType.loginFailed] && (
          <>
            <Callout intent={"danger"}>
              {props.errors[ErrorType.loginFailed].msg}
            </Callout>
            <br />
          </>
        )}
        <FormGroup label={t("MAILADRESS")}>
          <InputGroup
            value={mail}
            onChange={event => setMail(event.target.value)}
            large
            placeholder={t("MAILADRESS")}
            aria-label={t("MAILADRESS")}
          />
        </FormGroup>
        <FormGroup label={t("PASSWORD")}>
          <InputGroup
            large
            value={pass}
            onChange={e => setPass(e.target.value)}
            type="password"
            aria-label={t("PASSWORD")}
            placeholder={t("PASSWORD")}
          />
        </FormGroup>
        <ForgotPasswordDialog />
        <StyledDialog
          style={{ minHeight: 300 }}
          onClose={onReset}
          isOpen={!!(requiresCompanySelect && user)}
          title={t("SELECT_USER")}
        >
          <div className={DIALOG_BODY}>
            {requiresCompanySelect && user && (
              <>
                <Flex flexDirection="column" gap="12px">
                  {user.companies.map(c => (
                    <StyledSelectMenuItem
                      key={c.data}
                      onClick={() => dispatch(login(mail, pass, c.data))}
                    >
                      {c.label}
                      <StyledTopBarButton  
                        onClick={() => dispatch(login(mail, pass, c.data))}
                        icon={arrowIcon}
                      >
                        Fortfahren
                      </StyledTopBarButton>
                    </StyledSelectMenuItem>
                  ))}
                </Flex>
              </>
            )}
          </div>
          <div className={DIALOG_FOOTER}>
            <div className={DIALOG_FOOTER_ACTIONS}>
              <StyledButton onClick={onReset}>{t("CANCEL")}</StyledButton>
            </div>
          </div>
        </StyledDialog>
        <StyledButtonWrapper>
          <StyledTopBarButton
            type="submit"
            large
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onLogin(e);
            }}
          >
            {t("LOGIN")}
          </StyledTopBarButton>
        </StyledButtonWrapper>
      </form>
    </StyledLogin>
  );
};

export default connect(mapStateToProps)(LoginForm);
