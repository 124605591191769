import styled, { css } from 'styled-components';
import { CSS } from 'styled-components/dist/types';

export interface FlexProps {
  alignItems?: CSS.Property.AlignItems;
  alignContent?: CSS.Property.AlignContent;
  justifyItems?: CSS.Property.JustifyItems;
  justifyContent?: CSS.Property.JustifyContent;
  flexWrap?: CSS.Property.FlexWrap;
  flexDirection?: CSS.Property.FlexDirection;
  flex?: CSS.Property.Flex;
  flexGrow?: CSS.Property.FlexGrow;
  flexShrink?: CSS.Property.FlexShrink;
  flexBasis?: CSS.Property.FlexBasis;
  justifySelf?: CSS.Property.JustifySelf;
  alignSelf?: CSS.Property.AlignSelf;
  order?: CSS.Property.Order;
  gap?: CSS.Property.Gap;
}

/**
 *
 */
export const withFlex = (props: FlexProps) => css`
  ${props.alignItems && `align-items: ${props.alignItems};`}
  ${props.alignContent && `align-content: ${props.alignContent};`}
  ${props.justifyItems && `justify-items: ${props.justifyItems};`}
  ${props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  ${props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props.flex && `flex: ${props.flex};`}
  ${props.flexGrow && `flex-grow: ${props.flexGrow};`}
  ${props.flexShrink && `flex-shrink: ${props.flexShrink};`}
  ${props.flexBasis && `flex-basis: ${props.flexBasis};`}
  ${props.justifySelf && `justify-self: ${props.justifySelf};`}
  ${props.alignSelf && `align-self: ${props.alignSelf};`}
  ${props.order && `order: ${props.order};`}
  ${props.gap && `gap: ${props.gap};`}
`;


export type FlexStyleProps = FlexProps & {};

export const FlexStyled = styled.div<FlexStyleProps>`
  display: flex;
  ${withFlex}
`;
