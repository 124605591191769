import { Button, ButtonGroup } from "@blueprintjs/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { exportCart } from "../../store/actions/cart";
import { exportOrder } from "../../store/actions/order";
import { ReduxRootType } from "../../store/store";
import { ConnectedComponent, hexToRgba } from "../../utils";
import { CartMode } from "../Cart";
import CartImportDialog from "./CartImportDialog";
import DocumentsDialog from "./DocumentsDialog";
import { ExportIcon } from "../../assets/icons";

const StyledCornerBar = styled.div`
 display: flex;
 flex-direction: row;
 gap: 15px;
`;

export const StyledButton = styled(Button)`
  color: var(--bg-primary, "#00703D") !important;
  border: 1px solid var(--bg-primary, "#00703D");
  border-radius: 0px;
  background-color: white !important;
  outline: none;
  box-shadow: none !important;

  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  &:disabled {
    color: var(--text-text-foreground, #535B67) !important;
    border: 1px solid var(--text-text-foreground, #535B67);
    opacity: 0.5;
  }

  &:hover {
    color: ${hexToRgba('#00703D', 0.9)} !important;
    border: 1px solid ${hexToRgba('#00703D', 0.9)};
  }

  .bp5-icon {
    color: var(--bg-primary, "#00703D") !important;
  }
`

const mapStateToProps = (state: ReduxRootType) => ({
  orderId: state.orders.detail && state.orders.detail.id,
  documents: state.orders.detail && state.orders.detail.documents
});

interface CartCornerButtonRowProps
  extends ConnectedComponent<typeof mapStateToProps> {
  isVertical?: boolean;
  mode: CartMode;
}

const CartCornerButtonRow: React.FC<CartCornerButtonRowProps> = props => {
  const { t } = useTranslation();
  return (
    <StyledCornerBar>
      {props.mode !== CartMode.shop ? (
        <>
          <StyledButton
            disabled={!props.orderId}
            onClick={() => props.dispatch(exportOrder(props.orderId!))}
            icon={ExportIcon}
          >
            {t("EXPORT")}
          </StyledButton>
          <StyledButton
            disabled={!props.orderId}
            onClick={() => props.dispatch(exportOrder(props.orderId!, "PDF"))}
            icon={ExportIcon}
          >
            {t("EXPORT_PDF")}
          </StyledButton>
          <DocumentsDialog />
        </>
      ) : (
        <>
          <CartImportDialog />
          <StyledButton
            onClick={() => props.dispatch(exportCart())}
            minimal
            icon={ExportIcon}
          >
            {t("EXPORT")}
          </StyledButton>
        </>
      )}
    </StyledCornerBar>
  );
};

export default connect(mapStateToProps)(CartCornerButtonRow);
