import { Button, Label, Menu, Spinner } from "@blueprintjs/core";
import {
  ACTIVE,
  INLINE,
  MENU_ITEM
} from "@blueprintjs/core/lib/esm/common/classes";
import { ItemListRenderer, ItemRenderer, Suggest } from "@blueprintjs/select";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";

import { SipShopCoreServicesVoProduct } from "../../services/productCatalogue";
import { addToCart, addToCartWithMatId } from "../../store/actions/cart";
import {
  setQuickInsertProducts,
  setQuickInsertQuery
} from "../../store/reducers/cart";
import { ReduxRootType } from "../../store/store";
import { ConnectedComponent, debounce, hexToRgba } from "../../utils";
import { Flex } from "../Flex/Flex";
import { StyledButton } from "./CornerBar";

const StyledSuggestProductName = styled.small`
  max-width: 300px;
  display: block;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLabel = styled(Label)`
  display: flex !important;
  gap: 16px;
  flex-direction: row;
  margin: 0px !important;
  align-items: center;
  color: var(--text-primary, #535B67);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

const StyledWrapper = styled.div`
  .bp5-menu-item{
    color: var(--text-primary, #535B67) !important;
    &:hover {
      background-color: ${hexToRgba('#00703D', 0.1)} !important; 
    }
  }

  .bp5-menu-item.bp5-active{
    color: var(--text-primary, #535B67) !important;
    background-color: ${hexToRgba('#00703D', 0.1)} !important; 
  }

  .bp5-overlay-content{
    margin-top: 10px;
  }
`

const mapStateToProps = (state: ReduxRootType) => ({
  query: state.cart.quickInsert.query,
  products: state.cart.quickInsert.products
});

interface QuickInsertProps extends ConnectedComponent<typeof mapStateToProps> { }

const QuickInsert: React.FC<QuickInsertProps> = props => {
  const { t } = useTranslation();
  const debouncedCallback = useCallback(
    debounce((val: string | null) => {
      val && props.dispatch(addToCartWithMatId(val, true, false));
    }, 500),
    []
  );
  const dispatch = props.dispatch;

  const [query, setQuery] = useState(props.query);

  useEffect(() => setQuery(props.query), [props.query]);
  const onSelect = useCallback((item: SipShopCoreServicesVoProduct) => {
    props.dispatch(addToCart(item));
    props.dispatch(setQuickInsertQuery(""));
  }, []);
  const onQueryChange = useCallback(
    (
      query: string,
      event?: React.ChangeEvent<HTMLInputElement> | undefined
    ) => {
      // onQueryChange triggered twice by blueprintjs
      // must be a bug in the framework
      if (!event) {
        return;
      }

      const cleanQuery = query.trim().toUpperCase();

      // update query text
      dispatch(setQuickInsertQuery(cleanQuery));

      // if query is empty reset selected products
      if (cleanQuery === "") {
        dispatch(setQuickInsertProducts(null));
        return;
      }

      // if query did not change do nothing
      if (props.query === cleanQuery) {
        return;
      }
      // fetch data from server
      debouncedCallback(query);
    },
    [dispatch, debouncedCallback, props.query]
  );

  // const onInputChange = useCallback(
  //   (event: React.KeyboardEvent<HTMLInputElement>) =>
  //     debouncedCallback(props.query),
  //   [props.query]
  // );
  return (
    <form onSubmit={e => e.preventDefault()}>
      <StyledLabel className={INLINE}>
        {t("QUICKINSERT")}
        <Flex>
          <StyledWrapper>
          <StyledProductSuggest
            resetOnSelect
            onQueryChange={onQueryChange}
            inputProps={{
              placeholder: t("PRODUCT_NUMBER"),
            }}
            query={query}
            popoverProps={{
              minimal: true,
              usePortal: false,
              captureDismiss: false,
              placement:"bottom-start",
            }}
            scrollToActiveItem={true}
            onItemSelect={onSelect}
            // itemPredicate={(query, item) => item.matId.indexOf(query) !== -1}
            itemRenderer={itemRenderer}
            itemListRenderer={itemListRenderer}
            inputValueRenderer={item => item.matId}
            items={props.products ? props.products : []}
            selectedItem={
              props.products && query
                ? props.products.find(item => item.matId === query)
                : null
            }
          />
          </StyledWrapper>
          <StyledButton
            type="submit"
            disabled={!query}
            onClick={() =>
              query && props.dispatch(addToCartWithMatId(query))
            }
          >
            {t("INSERT")}
          </StyledButton>
        </Flex>
        {/* <InputGroup
            className={DARK}
            value={value}
            onChange={e => setValue(e.currentTarget.value)}
            placeholder={}
          /> */}
      </StyledLabel>
    </form >
  );
};

export default connect(mapStateToProps)(QuickInsert);

const QuickInsertMenuRendererWrapper: React.FC<{
  items: SipShopCoreServicesVoProduct[] | null | undefined;
  err: string | undefined;
  isLoading: boolean;
  children: ReactNode
}> = props => {
  if (props.err) {
    return <p>{props.err}</p>;
  }
  if (props.isLoading) {
    return (
      <>
        <br />
        <Spinner size={25} />
        <br />
      </>
    );
  }
  if (props.items === undefined) {
    return null;
    // return (
    //   <>
    //     <Spinner size={Spinner.SIZE_SMALL} />
    //   </>
    // );
  }

  return <>{props.children}</>;
};

const ConnectedQuickInsertMenuRendererWrapper = connect(
  (state: ReduxRootType) => ({
    items: state.cart.quickInsert.products,
    err: state.cart.quickInsert.error,
    isLoading:
      state.cart.quickInsert.products === undefined &&
      state.cart.quickInsert.query !== ""
  })
)(QuickInsertMenuRendererWrapper);

export const ProductSuggest = Suggest.ofType<SipShopCoreServicesVoProduct>();

const StyledProductSuggest = styled(ProductSuggest)`
  padding: 8px 12px; 
  border: 1px solid var(--border, #E4E4E7) !important;
  line-height: 22px !important;
  >input {

    &:disabled {
      opacity: 0.5;
    }
    padding: 0px !important;
    height: auto !important;
    box-shadow: none !important ;
    background: white !important;
    color: var(--text-primary, #535B67) !important;
    border: 0 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px !important; 
  }
`

const itemListRenderer: ItemListRenderer<SipShopCoreServicesVoProduct> = (props) => {

  return (
    props.filteredItems.length > 0 ?
    <Menu ulRef={props.itemsParentRef}>
      <ConnectedQuickInsertMenuRendererWrapper>
        {props.filteredItems.map((item, index) => {
          return props.renderItem(item, index);
        })}
      </ConnectedQuickInsertMenuRendererWrapper>
    </Menu> : null
  );
};

const itemRenderer: ItemRenderer<SipShopCoreServicesVoProduct> = (
  item,
  itemProps
) => {
  return (
    <div
      className={MENU_ITEM + (itemProps.modifiers.active ? " " + ACTIVE : "")}
      onClick={itemProps.handleClick}
      key={item.id}
    >
      <span>
        <b>{item.matId}</b>
        <StyledSuggestProductName>{item.name}</StyledSuggestProductName>
      </span>
    </div>
  );
};
