import i18next from "i18next";
import { ThunkDispatch } from "redux-thunk";

import { localeStringConverter } from "./i18n";
import {
  SipShopCoreServicesVoItem,
  SipShopCoreServicesVoProduct
} from "./services/productCatalogue";
import { DecorType } from "./store/reducers/cart";
import { ReduxRootType } from "./store/store";

/* safelyParseFloat returns a localized float string */
export const safelyParseFloat = (
  str?: string,
  locale?: string,
  options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2
  }
) => (str ? parseFloat(str).toLocaleString(locale, options) : "");

export const safelyFormatFloat = (
  val?: number,
  locale?: string,
  options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2
  }
) => (val ? val.toLocaleString(locale, options) : "");

/**
 * reorder items in a array
 * @param list
 * @param startIndex
 * @param endIndex
 */
export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const parseFloatFromLocalizedStr = (str: string, locale?: string) => {
  const thousandSeparator = (1111).toLocaleString(locale).replace(/1/g, "");
  const decimalSeparator = (1.1).toLocaleString(locale).replace(/1/g, "");
  if (thousandSeparator === '') {
      // pl_PL does not have a thousands separator
      return parseFloat(str.replace(new RegExp("\\" + decimalSeparator), "."));
  }
  return parseFloat(
    str
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
};
// return parseFloat(str.replace(/,(\d+)$/, ".$1"));

/**
 * replaces all \n characters with <br/>
 */
export const replaceNewLineWithBr = (str: string) =>
  // eslint-disable-next-line
  str.replace(new RegExp("\n", "g"), "<br/>");

  /**
 * replaces all \n characters with ,
 */
export const replaceNewLineWithComma = (str: string) =>
  // eslint-disable-next-line
  str.replace(new RegExp("\n", "g"), ", ");

/**
 *
 * @param product
 */
export const getCartItemFromProduct = (
  product: SipShopCoreServicesVoProduct
): SipShopCoreServicesVoItem =>
  ({
    cnt: product.minOrderCntPerUnit,
    cntPerUnit: product.cntPerUnit,
    currency: product.priceUnit ? product.priceUnit.data : null,
    deliveryDate: 0,
    statusUpdated: "0",
    deliveryDateCalculated: false,
    deliveryInfo: "",
    hasCustomDecor: product.hasCustomDecor,
    id: product.id,
    itemDescription: getProductDescription(product),
    itemName: product.name,
    matId: product.matId,
    minOrderCntPerUnit: product.minOrderCntPerUnit,
    packingUnit: product.packingUnit,
    posNoFormatted: 0,
    subPosNo: 0,
    price: "",
    priceCalculated: false,
    pricePerPiece: -1
  } as SipShopCoreServicesVoItem);

/**
 *
 * @param product
 */
export const getProductDescription = (
  product: SipShopCoreServicesVoProduct
) => {
  if (product.decorType < 0 || product.decorType > DecorType.NoDecor) {
    return "";
  }
  const t = i18next.t.bind(i18next);
  let descString = productDecorString(product);

  if (product.seal) {
    descString += ` - ${t("SEAL")} ${product.seal.label}`;
  }

  if (product.wrapping) {
    descString += " - " + product.wrapping.label;
  }
  return descString;
};

/**
 *
 * @param product
 */
export const productDecorString = (product: SipShopCoreServicesVoProduct) => {
  const t = i18next.t.bind(i18next);
  const decorId = Number(product.decorType);
  switch (decorId as DecorType) {
    case DecorType.BaseColor:
      return !product.baseColor ? "" : product.baseColor.label;
    case DecorType.InsideColor:
      return !product.baseColor
        ? ""
        : `${product.baseColor} - ${t("INSIDE")} ${product.insideDecor}`;
    case DecorType.OutsideColor:
      return !product.baseColor
        ? ""
        : `${product.baseColor} - ${t("OUTSIDE")} ${product.outsideDecor}`;
    case DecorType.BothsidesColorSimilar:
      return !product.baseColor
        ? ""
        : `${product.baseColor} - ${t("BOTH_SIDES")} ${product.insideDecor}`;
    case DecorType.BothsidesColor:
      return !product.baseColor
        ? ""
        : `${product.baseColor} - ${t("INSIDE")} ${product.insideDecor} - ${t(
            "OUTSIDE"
          )} ${product.outsideDecor}`;
    case DecorType.NoDecor:
      return !product.insideDecor ? "" : product.insideDecor.label;
  }
  return "";
};

export const debounce = <F extends (...args: any) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: NodeJS.Timeout | undefined;

  const debounced = (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const defaultTimeFormat: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};

export const dateFromUnixTimestampString = (timestamp: string) =>
  new Date(Number(timestamp) * 1000);

export const currencySymbol = (currency?: string) => {
  switch (currency) {
    case "EUR":
      return "€";
    case "PLN":
      return "zł";
    default:
      return "";
  }
};

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**
 * returns if a provided string is a valid email address
 * @param email
 */
export const isValidEmail = (email: string) =>
  emailRegex.test(String(email).toLowerCase());

/**
 * format a numerical value as (stored as number or string) to a localized price
 * tag format
 * @param val
 * @param locale
 * @param currency
 */
export const localizedPriceStr = (
  val: string | number,
  locale: string,
  currency?: string
) => {
  if (!val || val === -1 || val === "-1") {
    return " - ";
  }
  const currencyPrefix = currencySymbol(currency);
  return `${currencyPrefix ? currencyPrefix + " " : ""}${
    typeof val === "number"
      ? safelyFormatFloat(val, locale)
      : safelyParseFloat(val, locale)
  }`;
};

/**
 * converts a unix timestamp (number or string) to a localizes date string
 * @param timestamp
 * @param locale
 * @param options
 */
export const unixTimeToLocalizedStr = (
  timestamp: string | number,
  locale: string = localeStringConverter(i18next.language),
  options: Intl.DateTimeFormatOptions = defaultTimeFormat
) => {
    const d = new Date((typeof timestamp === "string" ? parseInt(timestamp) : timestamp) * 1000);
    if (d.getFullYear() === 9999 && d.getMonth() === 11 && d.getDate() === 31) {
        const t = i18next.t.bind(i18next);
        return t("INFO_PENDING");
    }
    return d.toLocaleDateString(locale, options);
}

export const getUnixTimestampt = (date: Date) => (date.getTime() / 1000) | 0;

/**
 * download a file by creating a link and clicking it via JS
 * @param dataUrl
 * @param filename
 */
export const downloadFileFromURL = (dataUrl: string, filename: string) => {
  //const url = window.URL.createObjectURL(dataUrl)
  const element = document.createElement("a");
  element.href = dataUrl; //url;
  element.download = filename;

  element.style.display = "none";
  element.click();

};

export type ConnectedComponent<
  M extends (...args: any) => any,
  T extends object = {}
> = ReturnType<M> & { dispatch: ThunkDispatch<ReduxRootType, {}, any> } & T;

export const hexToRgba = (hex: string, alpha: number) => {
  hex = hex.replace(/^#/, '');

  let r: number, g: number, b: number;

  if (hex.length === 6) {
    r = parseInt(hex.slice(0, 2), 16);
    g = parseInt(hex.slice(2, 4), 16);
    b = parseInt(hex.slice(4, 6), 16);
  }

  else if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else {
    return null; // Invalid hex code format
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
