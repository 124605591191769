import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { $lightGray1, $lightGray5 } from "../../theme";
import DefaultFlexBoxRowRenderer, {
  DraggableFlexBoxRowRenderer,
  FlexBoxRowRendererProps
} from "./FlexRow";
import { FlexBoxColumnProps } from "./FlexTable";

// eslint-disable-next-line
export const StyledFlexBody = styled.div<{
  striped?: boolean;
  scroll?: boolean;
}>`
  
  ${props =>
    props.scroll
      ? `
  overflow-y: scroll;
  min-height: 0;
  `
      : ""}
`;

const FlexBody: React.FC<{
  className?: string;
  columns: FlexBoxColumnProps[];
  draggable?: boolean;
  rowRenderer?: React.FC<FlexBoxRowRendererProps>;
  items: any[];
  selectionPredicate?: (T) => boolean;
  striped?: boolean;
  internalScroll?: boolean;
  keepRowSpaceWhenDragging?: boolean;
  draggableId?: (rowData: any, index: number) => string;
  onRowClick?: (props: { index: number; rowData: any }) => void;
}> = props => {
  const [sharedProps, setSharedProps] = useState({
    columns: props.columns,
    striped: props.striped,
    draggableId: props.draggableId,
    onRowClick: props.onRowClick,
    keepRowSpaceWhenDragging: props.keepRowSpaceWhenDragging,
    selectionPredicate: props.selectionPredicate
  });

  useEffect(
    () =>
      setSharedProps({
        columns: props.columns,
        striped: props.striped,
        draggableId: props.draggableId,
        onRowClick: props.onRowClick,
        keepRowSpaceWhenDragging: props.keepRowSpaceWhenDragging,
        selectionPredicate: props.selectionPredicate
      }),
    [
      props.columns,
      props.striped,
      props.draggableId,
      props.onRowClick,
      props.keepRowSpaceWhenDragging,
      props.selectionPredicate
    ]
  );

  return (
    <StyledFlexBody
      scroll={props.internalScroll}
      striped={props.striped}
      className={props.className}
    >
      {props.items.map((rowData, index) => {
        const rowProps = {
          index,
          rowData
        };
        if (props.rowRenderer) {
          return props.rowRenderer({ ...sharedProps, ...rowProps });
        } else if (props.draggable) {
          return (
            <DraggableFlexBoxRowRenderer
              key={index}
              {...sharedProps}
              {...rowProps}
            />
          );
        }
        return (
          <DefaultFlexBoxRowRenderer
            key={index}
            {...sharedProps}
            {...rowProps}
          />
        );
      })}
    </StyledFlexBody>
  );
};

export default FlexBody;
