import { Dispatch } from "redux";

import { asyncActionWrapper, getCatalogueService } from "../../services/api";
import {
  SipShopCoreServicesVoSearchRequest,
  SipShopCoreServicesVoSearchResult
} from "../../services/productCatalogue";
import { clearErrorAction, ErrorType, setErrorAction } from "../reducers/error";
import { SearchState, setSearchAction } from "../reducers/search";
import { setProductTreeAction, setSelectionPathAction } from "../reducers/shop";
import { ReduxRootType } from "../store";

/**
 * Search
 */
let searchRequestId = 0;

/**
 *
 * @param search
 * @param mergeWithStateSort
 */

const getSearchRequestFromState = (
  state: SearchState
): SipShopCoreServicesVoSearchRequest => ({
  searchText: state.searchText,
  seriesSelected: state.seriesSelected,
  productGroupId: state.productGroupId,
  matId: state.matId,
  depthSelected: state.depthSelected,
  colorSelected: state.colorSelected,
  sealSelected: state.sealSelected,
  baseColorSelected: state.baseColorSelected,
  sale: state.sale
});

export const setProductSeriesTree = (
  seriesId: string | undefined = undefined
) => async (dispatch: Dispatch, _getState: () => ReduxRootType) => {
  // reset current tree to indicate loading state
  dispatch(setProductTreeAction(null));

  const req: SipShopCoreServicesVoSearchRequest = {
    seriesSelected: seriesId === "" ? undefined : seriesId
  };
  const call = getCatalogueService().operations.search(req);

  try {
    const result = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.loadSeriesTree
    )) as SipShopCoreServicesVoSearchResult;

    // dispatch(setSelectedSeriesAction(
    //     result.seriesSelected ? {data: result.seriesSelected, label: ''} :
    //                             null));

    dispatch(setSelectionPathAction(null));
    dispatch(setProductTreeAction(result.tree));
  } catch {
    dispatch(setProductTreeAction([]));
  }
};
export const search = (
  search?: SipShopCoreServicesVoSearchRequest,
  mergeWithStateSort: boolean = true
) => async (dispatch: Dispatch, getState: () => ReduxRootType) => {
  dispatch(clearErrorAction(ErrorType.searchFailed));

  if (search === undefined) {
    dispatch(setSearchAction({}, false));
    return;
  }

  // construct request
  let req: SipShopCoreServicesVoSearchRequest = search;

  // if we should merge new sort with the currently set search params
  // spill them
  const state = getState();
  if (mergeWithStateSort) {
    req = {
      ...getSearchRequestFromState(state.search),
      ...req
    };
  }

  // update UI for the current search request

  const requestId = Date.now();
  // create a unique request id to check if a user has fetched in
  // between
  searchRequestId = requestId;

  // backend requires search text always to be set
  if (!req.searchText) {
    req.searchText = "";
  }

  if (req.seriesSelected === "") {
    req.seriesSelected = undefined;
  }

  // make sure the selected mat ID is upper cased
  if (req.matId) {
    req.matId = req.matId.toLocaleUpperCase();
  }

  // first update the UI state
  dispatch(setSearchAction({ ...req, tree: null }, false));
  // try getting search results
  try {
    const data = await new Promise((resolve, reject) => {
      const call = getCatalogueService().operations.search(req);
      call._handleError = () => reject(call.error);
      call.execute(data => resolve(data.data.result));
    });

    if (searchRequestId !== requestId) {
      console.debug("ignoring old search request");
      return;
    }

    const result = data as SipShopCoreServicesVoSearchResult;

    // set state search to server result
    dispatch(
      setSearchAction({
        seriesSelected:
          result.seriesSelected === "" ? undefined : result.seriesSelected,
        searchText: result.searchText === "" ? undefined : result.searchText,
        productGroupId:
          result.productGroupId === "" ? undefined : result.productGroupId,
        matId: result.matId === "" ? undefined : result.matId,
        // FIXME: define propper initial value
        depthSelected: result.depthSelected,
        colorSelected:
          result.colorSelected === "" ? undefined : result.colorSelected,
        sealSelected:
          result.sealSelected === "" ? undefined : result.sealSelected,
        baseColorSelected:
          result.baseColorSelected === ""
            ? undefined
            : result.baseColorSelected,

        // copy over search options
        baseColors: result.baseColors,
        colors: result.colors,
        seals: result.seals,
        tree: result.tree,
        sale: result.sale
      })
    );

    // if (result.series) {
    //   dispatch(setSeriesAction(result.series));
    //   dispatch(setSelectionPathAction(null));
    // }
  } catch (e: any) {
    // if we have a error message dispatch it
    if (e && e.data && e.data.exception) {
      dispatch(setErrorAction(ErrorType.searchFailed, e.data.exception));
    }
    dispatch(setErrorAction(ErrorType.searchFailed, { msg: "SEARCH_FAILED" }));
  }
};
