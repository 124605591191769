import { Button, Classes, MenuItem } from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CZ from "../assets/images/CZ.png";
import DE from "../assets/images/DE.png";
import ES from "../assets/images/ES.png";
import FR from "../assets/images/FR.png";
import GB from "../assets/images/GB.png";
import HU from "../assets/images/HU.png";
import IT from "../assets/images/IT.png";
import PL from "../assets/images/PL.png";
import PT from "../assets/images/PT.png";
import RO from "../assets/images/RO.png";
import RU from "../assets/images/RU.png";
import { SipShopCoreServicesVoLabeledData } from "../services/productCatalogue";
import { LanguageCode } from "../store/actions/user";
import { chevronDown } from "../assets/icons";

const StyledLanguageButton = styled(Button)`
  gap: 4px;

  &.bp5-button > * {
    margin: 0px;
  }

  .bp5-button-text {
    @media screen and (max-width: 1450px) {
      max-width: 0;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const languages = [
  { label: "DE", data: LanguageCode.DE },
  { label: "EN", data: LanguageCode.EN },
  { label: "ES", data: LanguageCode.ES },
  { label: "FR", data: LanguageCode.FR },
  { label: "IT", data: LanguageCode.IT },
  { label: "CS", data: LanguageCode.CZ },
  { label: "HU", data: LanguageCode.HU },
  { label: "PL", data: LanguageCode.PL },
  { label: "PT", data: LanguageCode.PT },
  { label: "RO", data: LanguageCode.RO },
  { label: "RU", data: LanguageCode.RU }
];

interface LanguageDropdownProps {
  onLanguageChanged: (lang: string) => void;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = props => {
  const { i18n } = useTranslation();
  const curLang = getLanguageItem(i18n.language as LanguageCode);
  if (!curLang) {
    console.error("Current language is unknown:", i18n.language);
    return null;
  }
  return (
    <StyleditemSelect
      filterable={false}
      items={languages}
      popoverProps={{ minimal: true, matchTargetWidth: true }}
      itemRenderer={languageSelectItemRenderer}
      onItemSelect={item => props.onLanguageChanged(item.data)}
    >
      <StyledLanguageButton
        className={Classes.MINIMAL}
        text={curLang.label}
        rightIcon={<div className="chevronIcon">{chevronDown}</div>}
        icon={
          <img
            alt={curLang.label}
            style={{ height: 16 }}
            src={getLanguageIcon(curLang.data)}
          />
        }
      />
    </StyleditemSelect>
  );
};

export default LanguageDropdown;

const ItemSelect = Select.ofType<SipShopCoreServicesVoLabeledData>();

const StyleditemSelect = styled(ItemSelect)`
  color: var(--bg-primary, "#00703D") !important;
  
  .bp5-menu-item{
    color: var(--text-primary, "#535B67") !important;
  }

  &.bp5-popover-open {
    background-color: var( --bg-secondary, #F4F4F5);
    transition: all ease-in 0.5;
    color: var(--bg-primary, "#00703D") !important;
    outline: none !important;
  }
  &.bp5-popover-open .bp5-button-text {
    color: var(--bg-primary, "#00703D") !important;
  }

  &.bp5-popover-open .chevronIcon svg{
    transform: rotate(180deg);
    color: var(--bg-primary, "#00703D") !important;
  }
`

const languageSelectItemRenderer: ItemRenderer<
  SipShopCoreServicesVoLabeledData
> = (item, props) => {
  return (
    <MenuItem
      onClick={props.handleClick}
      key={item.data}
      icon={
        <img
          style={{ height: 20 }}
          alt={item.label}
          src={getLanguageIcon(item.data)}
        />
      }
      text={item.label}
    />
  );
};

const getLanguageItem = (lang: LanguageCode) =>
  languages.find(item => item.data === lang);

export const getLanguageIcon = (lang: string | LanguageCode) => {
  switch (lang) {
    case LanguageCode.DE:
      return DE;
    case LanguageCode.FR:
      return FR;
    case LanguageCode.CZ:
      return CZ;
    case LanguageCode.EN:
      return GB;
    case LanguageCode.ES:
      return ES;
    case LanguageCode.HU:
      return HU;
    case LanguageCode.IT:
      return IT;
    case LanguageCode.PL:
      return PL;
    case LanguageCode.PT:
      return PT;
    case LanguageCode.RO:
      return RO;
    case LanguageCode.RU:
      return RU;
    default:
      console.error("unknown language code:", lang);
      return "";
  }
};
