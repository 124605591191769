import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";

import i18n from "../../i18n";
import { asyncActionWrapper, getSessionService } from "../../services/api";
import { clearErrorAction, ErrorType } from "../reducers/error";
import { setUserAction, setUserLocale, UserState } from "../reducers/user";
import { ReduxRootType } from "../store";

import { reloadCart, reloadCartEnv } from "./cart";
import { search } from "./search";
import { reloadSeriesList } from "./shop";
import { addErrorToast, addToast } from "../reducers/toasts";
import { resetAdminStateToInitial } from "../reducers/admin";

export enum LanguageCode {
  DE = "de_DE",
  FR = "fr_FR",
  CZ = "cz_CZ",
  EN = "en_GB",
  ES = "es_ES",
  HU = "hu_HU",
  IT = "it_IT",
  PL = "pl_PL",
  PT = "pt_PT",
  RO = "ro_RO",
  RU = "ru_RU"
}

export const login = (
  username: string,
  password: string,
  companyId: string = ""
) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const call = getSessionService().operations.serviceLogin(
    username,
    password,
    companyId
  );

  // try getting session
  try {
    const data = (await asyncActionWrapper(
      call,
      dispatch,
      ErrorType.loginFailed,
      true
    )) as UserState;

    // if this is the first login step and we have more then one company
    // require a company select
    if (data.companies.length > 1 && companyId === "") {
      // return required info for the UI to select a company
      return { requireCompanySelect: true, user: data };
    } else {
      // store user session
      dispatch(setUserAction(data));

      if (data.locale) {
        // make sure language matches
        i18n.changeLanguage(data.locale);
      }
      return { user: data };
    }
  } catch (e) {
    console.error("Login failed:", e);
    // reset user since something went wrong
    dispatch(setUserAction(null));
  }
};

/**
 * logout the current user user
 */
export const logout = () => async (
  dispatch: Dispatch,
  getState: () => ReduxRootType
) => {
  const call = getSessionService().operations.serviceLogout();

  // perform logout
  asyncActionWrapper(call, dispatch);

  const user = getState().user;

  localStorage.clear();
  sessionStorage.clear();

  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
  });

  /* keep email address for later re-login */
  dispatch(resetAdminStateToInitial())

  if (user !== null) {
    dispatch(
      setUserAction({
        aclGroup: "",
        brandId: "",
        companies: [],
        companyId: "",
        companyName: "",
        companySapId: "",
        deliveryDay: "",
        email: user.email,
        id: "",
        locale: user.locale,
        country: "",
        name: "",
        isAdmin: false
      })
    );
  } else {
    // clear state
    dispatch(setUserAction(null));
  }
};

/**
 * change password
 * @param oldPassword
 * @param newPassword
 */
export const setPassword = (oldPassword: string, newPassword: string) => async (
  dispatch: Dispatch
) => {
  const call = getSessionService().operations.serviceSetPassword(
    oldPassword,
    newPassword
  );

  // perform logout
  return asyncActionWrapper(call, dispatch);
};

/**
 * change user and UI language
 * @param lang
 */
export const setLanguage = (lang: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  dispatch(clearErrorAction(ErrorType.setLocaleFailed));

  // change language in UI
  i18n.changeLanguage(lang);

  // if the user has no session don't update
  const state = getState();
  if (state.user === null || state.user.id === "") {
    return;
  }

  const call = getSessionService().operations.serviceSetLocale(lang);
  // set remove locale
  await asyncActionWrapper(call, dispatch, ErrorType.setLocaleFailed);
  // store user session
  dispatch(setUserLocale(lang));

  // reload shop stuff
  dispatch(reloadSeriesList());

  // this will trigger a search with the current config
  dispatch(search({}));

  // reload current product if set
  // dispatch(reloadSelection());

  // reload cart env
  dispatch(reloadCartEnv());

  // reload cart
  dispatch(reloadCart());
};

export const forgotPassword = (email: string) => async (
  dispatch: ThunkDispatch<{}, {}, any>,
  getState: () => ReduxRootType
) => {
  const call = getSessionService().operations.forgotPassword(email);
  try {
    dispatch(
      addToast({
        message: "PASSWORD_RECOVER_SUCCESS",
        localize: true,
        intent: "success"
      })
    );
    await asyncActionWrapper(call, dispatch, ErrorType.forgotPasswordFailed);
  } catch {
    dispatch(addErrorToast("PASSWORD_RECOVER_FAIL", true));
  }
};
